import * as React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { CardCover, CardContent, Button, Box, Card, Stack } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { ip } from "../../ip";

export default function Training(props) {
  const videoRef = React.useRef(null);
  const user = props.user;
  const isMobile = props.isMobile;
  const [trainingVids, setTrainingVids] = useState([]);

  const video_title_map = {
    "accept_ask.mp4": "Accepting an Ask",
    "mark_fulfilled.mp4": "Marking an Ask as Fulfilled",
    "create_ask.mp4": "Creating an Ask",
    "training_video.mp4": "Complete Workflow",
  };

  useEffect(() => {
    // Fetch training videos
    axios.get(`${ip}/training-vids`).then((res) => {
      setTrainingVids(res.data);
    });
  }, []);

  const handleVideoClick = (videoRef) => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const handleFullScreen = (videoRef) => {
    const video = videoRef.current;
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) {
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) {
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) {
      video.msRequestFullscreen();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "100vh",
        padding: { xs: "16px", md: "32px" },
        backgroundColor: "#f9f9f9",
      }}
    >
      {/* Page Header */}
      <Stack spacing={2} alignItems="center" marginBottom="32px">
        <Typography
          level="h1"
          sx={{
            fontSize: { xs: "2rem", md: "3rem" },
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Training Videos
        </Typography>
        <Typography
          level="body-lg"
          sx={{
            fontSize: { xs: "1rem", md: "1.25rem" },
            textAlign: "center",
            maxWidth: "800px",
            color: "#555",
          }}
        >
          Watch these videos to learn how to effectively use the platform.
        </Typography>
      </Stack>

      {/* Video List */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
          gap: 4,
          width: "100%",
          maxWidth: "1200px",
        }}
      >
        {(user &&
        (user.account_type === "NPO" || user.account_type === "member")
          ? trainingVids.filter(
              (vid) =>
                vid.key === "accept_ask.mp4" || vid.key === "mark_fulfilled.mp4"
            )
          : trainingVids
        ).map((vid) => (
          <Card
            key={vid.key}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              aspectRatio: "16 / 9",
              borderRadius: "16px",
              overflow: "hidden",
              boxShadow: "md",
            }}
          >
            <Typography
              level="h4"
              sx={{
                fontSize: { xs: "1.25rem", md: "1.5rem" },
                fontWeight: "bold",
                padding: "16px",
                textAlign: "center",
              }}
            >
              {video_title_map[vid.key]}
            </Typography>
            <CardCover>
              <video
                ref={videoRef}
                muted
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                onClick={() => handleVideoClick(videoRef)}
              >
                <source src={vid.url} type="video/mp4" />
              </video>
            </CardCover>
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
                backgroundColor: "#fff",
              }}
            >
              <Button
                size="lg"
                variant="solid"
                color="primary"
                onClick={() => handleFullScreen(videoRef)}
              >
                <PlayArrowIcon sx={{ marginRight: "8px" }} />
                Full Screen
              </Button>
            </CardContent>
          </Card>
        ))}
      </Box>

      {/* Footer */}
      <Stack alignItems="center" marginTop="32px">
        <Typography level="body-lg" sx={{ color: "#555" }}>
          For further questions, contact us at{" "}
          <b>
            <a href="mailto:admin@gyftbay.com">admin@gyftbay.com</a>
          </b>
        </Typography>
      </Stack>
    </Box>
  );
}
