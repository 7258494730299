import React from "react";
import "../style/nav.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Dropdown, MenuButton, Menu, MenuItem, Stack } from "@mui/joy";
import { ip } from "../ip";
import logo from "../images/gyftbay_light_blue_lowercase.png";

function Nav(props) {
  axios.defaults.withCredentials = true;
  const user = props.user;
  const setUser = props.setUser;
  const isAuthenticated = props.isAuthenticated;
  const setIsAuthenticated = props.setIsAuthenticated;
  const isMobile = props.isMobile;
  const tenant = props.tenant;
  const navigate = useNavigate();

  function Logout() {
    if (user) {
      axios
        .delete(`${ip}/logout`, { withCredentials: true })
        .then((response) => {
          setUser({});
          setIsAuthenticated(false);
        });
      navigate(`/${tenant}`, { state: { loggedOut: true } });
    }
  }

  const navStyle = {
    height: "50px",
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const mobileNavStyle = {
    height: "40px",
    padding: "0 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const menuButtonStyle = {
    padding: "12px 24px",
    fontSize: "18px",
    height: "40px",
  };

  const logoStyle = {
    width: "auto",
    height: "40px",
    cursor: "pointer",
    transform: isMobile ? "scale(1.3)" : "scale(1.5)",
  };

  const render_mobile = () => {
    return user && user["id"] ? (
      user["account_type"] === "NPO" || user["account_type"] === "member" ? (
        <div>
          <nav className="nav" style={mobileNavStyle}>
            <img
              src={logo}
              alt="GyftBay Logo"
              style={logoStyle}
              onClick={() => {
                if (isAuthenticated) {
                  navigate(`/${user.tenant}/home`);
                } else {
                  navigate(`/${user.tenant}`);
                }
              }}
            />
            <Dropdown>
              <MenuButton
                variant="solid"
                color="primary"
                style={menuButtonStyle}
              >
                ☰ Menu
              </MenuButton>
              <Menu>
                <MenuItem
                  onClick={() => navigate(`/${user.tenant}/landingpage`)}
                >
                  Landing Page
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${user.tenant}/home`)}>
                  All Asks
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${user.tenant}/accepted`)}>
                  Status Of Asks
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${user.tenant}/profile`)}>
                  Profile
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${tenant}/donate`)}>
                  Make A Donation
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${user.tenant}/training`)}>
                  Help
                </MenuItem>
                <MenuItem color="danger" onClick={Logout}>
                  Logout
                </MenuItem>
              </Menu>
            </Dropdown>
          </nav>
        </div>
      ) : user["account_type"] === "Admin" ? (
        <div>
          <nav className="nav" style={mobileNavStyle}>
            <img
              src={logo}
              alt="GyftBay Logo"
              style={logoStyle}
              onClick={() => {
                if (isAuthenticated) {
                  navigate(`/${user.tenant}/home`);
                } else {
                  navigate(`/${user.tenant}`);
                }
              }}
            />
            <Dropdown>
              <MenuButton
                variant="solid"
                color="primary"
                style={menuButtonStyle}
              >
                ☰ Menu
              </MenuButton>
              <Menu>
                <MenuItem
                  onClick={() => navigate(`/${user.tenant}/landingpage`)}
                >
                  Landing Page
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${user.tenant}/home`)}>
                  My Asks
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${user.tenant}/create`)}>
                  Create Ask
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${user.tenant}/orgAsks`)}>
                  Organization Asks
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${user.tenant}/inventory`)}>
                  Inventory
                </MenuItem>
                <MenuItem
                  onClick={() => navigate(`/${user.tenant}/addInventory`)}
                >
                  Add Inventory
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${user.tenant}/profile`)}>
                  Profile
                </MenuItem>
                <MenuItem color="danger" onClick={Logout}>
                  Logout
                </MenuItem>
              </Menu>
            </Dropdown>
          </nav>
        </div>
      ) : (
        <div>
          <nav className="nav" style={mobileNavStyle}>
            <img
              src={logo}
              alt="GyftBay Logo"
              style={logoStyle}
              onClick={() => {
                if (isAuthenticated) {
                  navigate(`/${user.tenant}/home`);
                } else {
                  navigate(`/${user.tenant}`);
                }
              }}
            />
            <Dropdown>
              <MenuButton
                variant="solid"
                color="primary"
                style={menuButtonStyle}
              >
                ☰ Menu
              </MenuButton>
              <Menu>
                <MenuItem
                  onClick={() => navigate(`/${user.tenant}/landingpage`)}
                >
                  Landing Page
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${user.tenant}/home`)}>
                  My Asks
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${user.tenant}/create`)}>
                  Create Ask
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${user.tenant}/inventory`)}>
                  Inventory
                </MenuItem>
                <MenuItem
                  onClick={() => navigate(`/${user.tenant}/addInventory`)}
                >
                  Add Inventory
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${user.tenant}/profile`)}>
                  Profile
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${user.tenant}/training`)}>
                  Help
                </MenuItem>
                <MenuItem color="danger" onClick={Logout}>
                  Logout
                </MenuItem>
              </Menu>
            </Dropdown>
          </nav>
        </div>
      )
    ) : (
      <div>
        <nav className="nav" style={mobileNavStyle}>
          <img
            src={logo}
            alt="GyftBay Logo"
            style={logoStyle}
            onClick={() => {
              if (isAuthenticated) {
                navigate(`/${user.tenant}/home`);
              } else {
                navigate(`/${user.tenant || tenant}`);
              }
            }}
          />
          <Dropdown>
            <MenuButton variant="solid" color="primary" style={menuButtonStyle}>
              ☰ Menu
            </MenuButton>
            <Menu>
              <MenuItem onClick={() => navigate(`/${tenant}/browse`)}>
                Browse Items
              </MenuItem>
              <MenuItem onClick={() => navigate(`/${user.tenant}/login`)}>
                Login
              </MenuItem>
              <MenuItem onClick={() => navigate(`/${user.tenant}/about-gyftbay`)}>
                About
              </MenuItem>
              <MenuItem onClick={() => navigate(`/${tenant}/contact`)}>
                Contact
              </MenuItem>
              <MenuItem onClick={() => navigate(`/${tenant}/donate`)}>
                Donate
              </MenuItem>
            </Menu>
          </Dropdown>
        </nav>
      </div>
    );
  };

  const render_desktop = () => {
    return user && user["id"] ? (
      <div>
        {user["account_type"] === "NPO" || user["account_type"] === "member" ? (
          <nav className="nav" style={navStyle}>
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              <img
                src={logo}
                alt="GyftBay Logo"
                style={logoStyle}
                onClick={() => {
                  if (isAuthenticated) {
                    navigate(`/${user.tenant}/home`);
                  } else {
                    navigate(`/${user.tenant || tenant}`);
                  }
                }}
              />
              <ul style={{ display: "flex", marginLeft: "20px" }}>
                <Dropdown>
                  <MenuButton
                    variant="solid"
                    color="primary"
                    onClick={() => navigate(`/${user.tenant}/landingpage`)}
                    style={menuButtonStyle}
                  >
                    Landing Page
                  </MenuButton>
                </Dropdown>
                <Dropdown>
                  <MenuButton
                    variant="solid"
                    color="primary"
                    style={menuButtonStyle}
                  >
                    Home
                  </MenuButton>
                  <Menu>
                    <MenuItem onClick={() => navigate(`/${user.tenant}/home`)}>
                      All Asks
                    </MenuItem>
                    <MenuItem
                      onClick={() => navigate(`/${user.tenant}/accepted`)}
                    >
                      Status Of Asks
                    </MenuItem>
                    <MenuItem onClick={() => navigate(`/${tenant}/donate`)}>
                      Make A Donation
                    </MenuItem>
                  </Menu>
                </Dropdown>
              </ul>
            </div>
            <ul style={{ display: "flex", justifyContent: "flex-end" }}>
              <Dropdown>
                <MenuButton
                  variant="solid"
                  color="primary"
                  style={menuButtonStyle}
                >
                  My Account
                </MenuButton>
                <Menu>
                  <MenuItem onClick={() => navigate(`/${user.tenant}/profile`)}>
                    Profile
                  </MenuItem>
                  <MenuItem color="danger" onClick={Logout}>
                    Logout
                  </MenuItem>
                </Menu>
              </Dropdown>
              <Dropdown>
                <MenuButton
                  variant="solid"
                  color="primary"
                  style={menuButtonStyle}
                >
                  Help
                </MenuButton>
                <Menu>
                  <MenuItem
                    onClick={() => navigate(`/${user.tenant}/training`)}
                  >
                    Training Videos
                  </MenuItem>
                </Menu>
              </Dropdown>
            </ul>
          </nav>
        ) : (
          // School/Navigator
          <nav className="nav" style={navStyle}>
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              <img
                src={logo}
                alt="GyftBay Logo"
                style={logoStyle}
                onClick={() => {
                  if (isAuthenticated) {
                    navigate(`/${user.tenant}/home`);
                  } else {
                    navigate(`/${tenant} || ${user.tenant}`);
                  }
                }}
              />
              <ul style={{ display: "flex", marginLeft: "20px" }}>
                <Dropdown>
                  <MenuButton
                    variant="solid"
                    color="primary"
                    onClick={() => navigate(`/${user.tenant}/landingpage`)}
                    style={menuButtonStyle}
                  >
                    Landing Page
                  </MenuButton>
                </Dropdown>
                <Dropdown>
                  <MenuButton
                    variant="solid"
                    color="primary"
                    style={menuButtonStyle}
                  >
                    Home
                  </MenuButton>
                  <Menu>
                    <MenuItem onClick={() => navigate(`/${user.tenant}/home`)}>
                      My Asks
                    </MenuItem>
                    <MenuItem
                      onClick={() => navigate(`/${user.tenant}/create`)}
                    >
                      Create Ask
                    </MenuItem>
                    {user['account_type'] === "Admin" && (
                    <MenuItem
                      onClick={() => navigate(`/${user.tenant}/orgAsks`)}
                    >
                      Organization Asks
                    </MenuItem>
                    )}
                  </Menu>
                </Dropdown>
                <Dropdown>
                  <MenuButton
                    variant="solid"
                    color="primary"
                    style={menuButtonStyle}
                  >
                    Inventory
                  </MenuButton>
                  <Menu>
                    <MenuItem
                      onClick={() => navigate(`/${user.tenant}/inventory`)}
                    >
                      Inventory
                    </MenuItem>
                    <MenuItem
                      onClick={() => navigate(`/${user.tenant}/addInventory`)}
                    >
                      Add Inventory
                    </MenuItem>
                  </Menu>
                </Dropdown>
              </ul>
            </div>
            <ul style={{ display: "flex", justifyContent: "flex-end" }}>
              <Dropdown>
                <MenuButton
                  variant="solid"
                  color="primary"
                  style={menuButtonStyle}
                >
                  My Account
                </MenuButton>
                <Menu>
                  <MenuItem onClick={() => navigate(`/${user.tenant}/profile`)}>
                    Profile
                  </MenuItem>
                  <MenuItem color="danger" onClick={Logout}>
                    Logout
                  </MenuItem>
                </Menu>
              </Dropdown>
              <Dropdown>
                <MenuButton
                  variant="solid"
                  color="primary"
                  style={menuButtonStyle}
                >
                  Help
                </MenuButton>
                <Menu>
                  <MenuItem
                    onClick={() => navigate(`/${user.tenant}/training`)}
                  >
                    Training Videos
                  </MenuItem>
                </Menu>
              </Dropdown>
            </ul>
          </nav>
        )}
      </div>
    ) : (
      // Non-logged-in users
      <div>
        <nav
          className="nav"
          style={{
            height: "50px",
            padding: "0 20px",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={logo}
              alt="GyftBay Logo"
              style={logoStyle}
              onClick={() => {
                navigate(`/${tenant}`);
              }}
            />
          </div>
          <ul style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <Dropdown>
              <MenuButton
                variant="solid"
                color="primary"
                onClick={() => navigate(`/${tenant}/login`)}
                style={menuButtonStyle}
              >
                Login
              </MenuButton>
            </Dropdown>
            {/* <Dropdown>
                <MenuButton
                  variant="solid"
                  color="primary"
                  onClick={() => navigate("/signup")}
                  style={menuButtonStyle}
                >
                  Signup
                </MenuButton>
              </Dropdown> */}
            <Dropdown>
              <MenuButton
                variant="solid"
                color="primary"
                onClick={() => navigate(`${tenant}/about-gyftbay`)}
                style={menuButtonStyle}
              >
                About
              </MenuButton>
            </Dropdown>
            <Dropdown>
              <MenuButton
                variant="solid"
                color="primary"
                onClick={() => navigate(`/${tenant}/contact`)}
                style={menuButtonStyle}
              >
                Contact
              </MenuButton>
            </Dropdown>
            <Dropdown>
              <MenuButton
                variant="solid"
                color="primary"
                style={menuButtonStyle}
              >
                Donate
              </MenuButton>
              <Menu>
                <MenuItem onClick={() => navigate(`/${tenant}/donate`)}>
                  Monetary Donations
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${tenant}/browse`)}>
                  Donate Items
                </MenuItem>
              </Menu>
            </Dropdown>
          </ul>
        </nav>
      </div>
    );
  };

  return (
    <Stack justifyContent={"flex-end"}>
      {isMobile ? render_mobile() : render_desktop()}
    </Stack>
  );
}

export default Nav;
