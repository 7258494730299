import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";
import { ip } from "../ip";
import {
  Box,
  Stack,
  Typography,
  Card,
  Button,
  Input,
  CircularProgress,
  Textarea,
  FormLabel,
} from "@mui/joy/";

function Contact(props) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [organization, setOrganization] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const Success_Message = props.Success_Message;
  const Error_Message = props.Error_Message;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const handleSubmit = () => {
    if (
      !email ||
      !name ||
      !email.includes("@")
    ) {
      setError("Please fill out all fields correctly.");
      Error_Message("Please ensure all fields are valid!");
      return;
    }

    setSubmitting(true);
    setTimeout(() => {
      axios
        .post(`${ip}/addcontact`, {
          email,
          name,
          organization,
          phoneNumber,
          message,
        })
        .then((response) => {
          if (response.data.message === "Contact Added!") {
            Success_Message("Contact Received, Thank You!");
          }
          setEmail("");
          setName("");
          setOrganization("");
          setPhoneNumber("");
          setMessage("");
        })
        .catch(() => {
          Error_Message("Something went wrong. Please try again.");
        })
        .finally(() => {
          setSubmitting(false);
        });
    }, 2000);
  };

  return (
    <Box
      sx={{
        padding: { xs: "16px", md: "64px" },
        maxWidth: "900px",
        margin: "auto",
        height: "100vh",
      }}
    >
      <ToastContainer />

      {/* Hero Section */}
      <Box sx={{ textAlign: "center", marginBottom: "48px" }}>
        <Typography
          level="h1"
          component="h1"
          sx={{ fontWeight: "bold", marginBottom: "16px" }}
        >
          Get in Touch
        </Typography>
        <Typography
          level="body1"
          sx={{ color: "text.secondary", maxWidth: "600px", margin: "auto" }}
        >
          Whether you're interested in partnering with us, supporting your
          community, or have general questions, we'd love to hear from you. Fill
          out the form below or email us directly at{" "}
          <a href="mailto:admin@gyftbay.com">admin@gyftbay.com</a>, and we'll
          get back to you shortly!
        </Typography>
      </Box>

      {/* Contact Form */}
      <Card variant="outlined" sx={{ padding: "32px", borderRadius: "16px" }}>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <FormLabel>
              <b>Name</b>
            </FormLabel>
            <Input
              placeholder="John Doe"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={error === "name"}
            />
          </Stack>

          <Stack spacing={1}>
            <FormLabel>
              <b>Email</b>
            </FormLabel>
            <Input
              type="email"
              placeholder="example@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={error === "email"}
            />
          </Stack>

          <Stack spacing={1}>
            <FormLabel>
              <b>Organization</b> (optional)
            </FormLabel>
            <Input
              placeholder="Your Organization Name"
              value={organization}
              onChange={(e) => setOrganization(e.target.value)}
            />
          </Stack>

          <Stack spacing={1}>
            <FormLabel>
              <b>Phone Number</b> (optional)
            </FormLabel>
            <Input
              placeholder="123-456-7890"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Stack>

          <Stack spacing={1}>
            <FormLabel>
              <b>Message</b> <span style={{ color: "#888" }}>(Optional)</span>
            </FormLabel>
            <Textarea
              minRows={4}
              placeholder="Your message here..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Stack>

          <Button
            onClick={handleSubmit}
            color="primary"
            size="lg"
            disabled={submitting}
            sx={{ alignSelf: "flex-start" }}
          >
            {submitting ? <CircularProgress /> : "Submit"}
          </Button>

          {error && (
            <Typography level="body2" sx={{ color: "red", marginTop: "16px" }}>
              {error}
            </Typography>
          )}
        </Stack>
      </Card>
    </Box>
  );
}

export default Contact;
