import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, React } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CssVarsProvider } from "@mui/joy/styles";
import {
  Button,
  Input,
  FormLabel,
  FormControl,
  Typography,
  Sheet,
  Stack,
  Box,
} from "@mui/joy";
import ReactGA from "react-ga";
import healthhub from "../images/healthhub.png";
import gyftbaylogo from "../images/gyftbay_blue_lowercase.png";
import { ip } from "../ip.js";

function Login(props) {
  axios.defaults.withCredentials = true;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const setUser = props.setUser;
  const setIsAuthenticated = props.setIsAuthenticated;
  const [err, setErr] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const tenant = props.tenant;
  const setTenant = props.setTenant;
  const isMobile = props.isMobile;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const checkLoginStatus = async () => {
      try {
        const response = await axios.get(`${ip}/login/login`, {
          withCredentials: true,
        });
        if (response.data.loggedIn === true) {
          setIsAuthenticated(true);
          setUser(response.data.user);
          navigate(1);
        } else {
          navigate(`/${tenant}/login`, { replace: true });
        }
      } catch (error) {
        console.error("Error checking login status:", error);
      }
    };
    checkLoginStatus();
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    axios
      .post(
        `${ip}/login/login`,
        {
          email,
          password,
          tenant,
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data.message) {
          setErr(response.data.message);
          Error_Message(response.data.message);
        } else {
          if (response.data) {
            setUser(response.data[0]);
            setIsAuthenticated(true);
            setTenant(response.data[0].tenant);
            navigate(`/${response.data[0].tenant}/landingPage`, {
              state: { toast: true, loggedIn: true },
            });
          }
        }
      });
  };

  const Error_Message = (message) => {
    toast.error(message, {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin(e);
    }
  };

  function showLoggedOut() {
    if (location && location.state && location.state.loggedOut) {
      toast.success("Logged Out Successfully!", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        toastId: "ask_created",
        onClose: () => {
          location.state.loggedOut = false;
        },
      });
    }
  }

  return (
    <CssVarsProvider>
      <Stack
        direction={isMobile ? "column" : "row"}
        gap={2}
        sx={{ display: "block", margin: "auto" }}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <img
          src={healthhub}
          loading="lazy"
          alt=""
          style={{
            width: isMobile ? "40%" : "20%",
            height: "auto",
            display: "block",
            margin: "auto",
          }}
        />
        <img
          src={gyftbaylogo}
          loading="lazy"
          alt=""
          style={{
            width: isMobile ? "40%" : "20%",
            height: "auto",
            display: "block",
            margin: "auto",
          }}
        />
      </Stack>
      <Box
        sx={{
          minHeight: isMobile ? "900px" : 700,
          alignContent: "center",
          display: "flex",
          flexDirection: "column",
          gap: 3,
          overflowX: "auto",
          alignItems: "center",
          padding: "0",
          height: "100%",
        }}
      >
        <main>
          <Sheet
            sx={{
              width: { xs: "90%", md: "400px" },
              mx: "auto", // margin left & right
              my: 4, // margin top & bottom
              py: { xs: 4, md: 6 }, // padding top & bottom
              px: { xs: 3, md: 5 }, // padding left & right
              display: "flex",
              flexDirection: "column",
              gap: 3,
              borderRadius: "sm",
              boxShadow: "md",
              fontSize: { xs: "0.9rem", md: "1rem" },
            }}
            variant="outlined"
          >
            {showLoggedOut()}
            <Stack spacing={2}>
              <ToastContainer />
              <Typography
                level="h4"
                component="h1"
                sx={{
                  fontSize: { xs: "1.5rem", md: "2rem" },
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Welcome!
              </Typography>
              <Typography
                level="body-sm"
                sx={{ fontSize: { xs: "0.9rem", md: "1rem" }, textAlign: "center", }}
              >
                Sign in to continue.
              </Typography>
            </Stack>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                type="email"
                placeholder="johndoe@email.com"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input
                name="password"
                type="password"
                placeholder="password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyPress}
                autoComplete="current-password"
              />
            </FormControl>
            <Button
              sx={{
                mt: 1,
                fontSize: { xs: "0.9rem", md: "1rem" },
                padding: { xs: "10px 20px", md: "12px 24px" },
              }}
              onClick={handleLogin}
              type="submit"
            >
              Log in
            </Button>
          </Sheet>
        </main>
      </Box>
    </CssVarsProvider>
  );
}

export default Login;
