import { React, useEffect, useState } from "react";
import axios from "axios";
import Product from "./Product";
import About from "./About";
import {
  Typography,
  Button,
  Stack,
  Card,
  CircularProgress,
  Select,
  Option,
  Modal,
  ModalDialog,
  Input,
  Box,
} from "@mui/joy";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import { ip } from "../../ip";

function Accepted(props) {
  axios.defaults.withCredentials = true;
  const [data, setData] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const user = props.user;
  const [statusClick, setStatusClick] = useState(false);
  const [status, setStatus] = useState(4);
  const [open, setOpen] = useState(null);
  const [openMark, setOpenMark] = useState(null);
  const [openCancelAll, setOpenCancelAll] = useState(null);
  const [checkedStates, setCheckedStates] = useState([]);
  const [tracking, setTracking] = useState("");
  const Success_Message = props.Success_Message;
  const Error_Message = props.Error_Message;
  const confetti = props.confetti;
  const isAuthenticated = props.isAuthenticated;
  const isMobile = props.isMobile;
  const tenant = props.tenant;

  function Email(
    to_email_id,
    from_email,
    from_name,
    from_organization,
    content,
    status,
    ask_id
  ) {
    axios.get(`${ip}/email/${to_email_id}`).then((response) => {
      if (response.data) {
        emailjs
          .send(
            "service_f5sai5q",
            "template_bs16y39",
            {
              to_email: response.data.email,
              from_email: from_email,
              from_name: from_name,
              from_organization: from_organization || "N/A",
              content: content,
              status: status,
              ask_id: ask_id,
            },
            "x7LM43rlkJsqov2r7"
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("email sent");
            } else {
              console.log("email not sent");
            }
          });
      }
    });
  }

  function createContent(products) {
    let content = "";
    for (let i = 0; i < products.length; i++) {
      if (products[i].id !== null) {
        content += products[i].product_name;
        content += ": ";
        content += products[i].quantity;
        if (i !== products.length - 1) {
          content += ",\n";
        }
      }
    }
    return content;
  }

  function changeStatus(id, product_name, new_status) {
    axios
      .put(`${ip}/asks/changeStatus/`, {
        id: id,
        product_name: product_name,
        new_status: new_status,
        user_id: user.id,
        tenant: user.tenant || tenant,
      })
      .then((response) => {
        if (response.data) {
          setStatusClick(!statusClick);
          if (new_status === 2) {
            Success_Message("Marked as Fulfilled!");
            confetti.addConfetti();
          }
        }
      });
  }

  function show_accept(index) {
    //loop through checkedStates and check if any are not null
    if (!checkedStates[index]) return false;
    for (let i = 0; i < checkedStates[index].length; i++) {
      if (checkedStates[index][i] && checkedStates[index][i].id !== null) {
        return true;
      }
    }
    return false;
  }

  function show_tracking(index) {
    //loop through checkedStates and check if any are not null
    if (!checkedStates[index]) return false;
    if (tracking) return true;
    for (let i = 0; i < checkedStates[index].length; i++) {
      if (checkedStates[index][i] && checkedStates[index][i].status === 2) {
        return true;
      }
    }
    return false;
  }

  //function to submit traking info, WORK IN PROGRESS
  function submitTracking(index, group) {
    //loop through checkedStates and check if any are not null
    axios
      .post(`${ip}/asks/addTracking/`, {
        tracking: tracking,
        group: group,
        checkedStates: checkedStates[index],
      })
      .then((response) => {
        if (response.data) {
          setStatusClick(!statusClick);
          Success_Message("Tracking Info Added!");
        }
      });
  }

  function changeMultipleStatus(id, group, new_status) {
    axios
      .put(`${ip}/asks/changeMultipleStatus/`, {
        id: id,
        group: group,
        new_status: new_status,
        tenant: user.tenant || tenant,
      })
      .then((response) => {
        if (response.data) {
          setStatusClick(!statusClick);
        }
      })
      .then(() => {
        if (new_status === 2) {
          Success_Message("Marked As Fulfilled!");
          confetti.addConfetti();
        } else if (new_status === 0) {
          Error_Message("Asks Canceled!");
        }
      });
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${ip}/asks/acceptedAsks/${user.id}/${status}`
      );
      setData(response.data);
      const initialCheckedStates = response.data.map((group) =>
        Array(group.products.length).fill({
          id: null,
          product_name: null,
          quantity: null,
        })
      );
      setCheckedStates(initialCheckedStates);
      setLoading(false);
      setTracking("");
    };
    fetchData();
  }, [statusClick, user.id, status]);

  useEffect(() => {
    if (!data) return;

    const lowerCaseSearchQuery = searchQuery.toLowerCase();

    // Filter data based on search query
    // change filter to include date, school, address, and product name
    const filtered = data.filter(
      (group) =>
        (group.ask[0].addr &&
          group.ask[0].addr.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (group.ask[0].schoolAddr &&
          group.ask[0].schoolAddr
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (group.ask[0].created &&
          group.ask[0].created.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (group.ask[0].organization &&
          group.ask[0].organization
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (group.ask[0].school &&
          group.ask[0].school.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (group.ask[0].healthhub &&
          group.ask[0].healthhub
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        group.products.some((product) =>
          product.product_name.toLowerCase().includes(lowerCaseSearchQuery)
        )
    );

    setFilteredData(filtered);
  }, [searchQuery, data]);

  const show_num_checked = (index) => {
    let count = 0;
    for (let i = 0; i < checkedStates[index].length; i++) {
      if (checkedStates[index][i] && checkedStates[index][i].id !== null) {
        count++;
      }
    }
    return count;
  };

  const handleCheckBoxChange = (e, askIndex, productIndex, item) => {
    setCheckedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[askIndex][productIndex] = e.target.checked
        ? {
            acceptance_id: item.acceptance_id,
            id: item.id,
            product_name: item.product_name,
            accepted_quantity: item.accepted_quantity,
            status: item.status,
          }
        : {
            acceptance_id: null,
            id: null,
            product_name: null,
            accepted_quantity: null,
            status: null,
          };
      return newStates;
    });
  };

  if (loading || !data) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          margin: "auto",
          pb: 10,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  //
  const check_checked_states = (index) => {
    for (let i = 0; i < checkedStates[index]?.length; i++) {
      if (
        checkedStates &&
        checkedStates[index] &&
        checkedStates[index][i] &&
        checkedStates[index][i].id !== null
      ) {
        return true;
      }
    }
  };

  return (
    <Box sx={{ px: { xs: 2, md: 6 }, pb: 10 }}>
      {loading && <CircularProgress />}
      <Stack spacing={2} sx={{ justifyContent: "space-between" }}>
        <ToastContainer />
        <Stack
          spacing={1}
          direction={isMobile ? "column" : "row"}
          alignItems="center"
          sx={{ display: "flex", alignItems: "left" }}
        >
          <Typography level="h1" sx={{ marginRight: "10px", width: "auto" }}>
            Status of Asks
          </Typography>
          <Select size="lg" value={status}>
            <Option
              value={4}
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  setStatus(4);
                  setLoading(false);
                }, 500);
              }}
            >
              Show All
            </Option>
            <Option
              value={1}
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  setStatus(1);
                  setLoading(false);
                }, 500);
              }}
            >
              Accepted
            </Option>
            <Option
              value={2}
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  setStatus(2);
                  setLoading(false);
                }, 500);
              }}
            >
              Fulfilled
            </Option>
            <Option
              value={3}
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  setStatus(3);
                  setLoading(false);
                }, 500);
              }}
            >
              Completed
            </Option>
          </Select>
        </Stack>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography level="body-lg">Search By:</Typography>
          <Input
            type="text"
            placeholder="Item, Date, Address, School, Organization"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            sx={{ width: isMobile ? "auto" : "400px" }}
            id="search"
          />
        </Stack>
        <Stack spacing={1} direction="row" justifyContent="space-between">
          <Typography level="h4">
            {status === 1
              ? "Accepted Asks"
              : status === 2
              ? "Fulfilled Asks"
              : status === 3
              ? "Completed Asks"
              : "All"}
          </Typography>
          {status === 4 && (
            <Typography level="body-sm" alignItems="flex-end">
              <i>
                To change status, add tracking info, use the status dropdown
                above
              </i>
            </Typography>
          )}
        </Stack>

        {filteredData.length === 0 ? (
          <Card>
            <Typography level="body-lg">
              No Asks, check back later or refine search
            </Typography>
          </Card>
        ) : (
          filteredData.map((group, index) => (
            <Stack spacing={1} key={index}>
              <Card
                color="neutral"
                invertedColors={false}
                orientation="vertical"
                size="md"
                variant="outlined"
              >
                <About
                  data={group.ask}
                  user={user}
                  isAuthenticated={isAuthenticated}
                />
                <Product
                  data={group.products}
                  user={user}
                  changeStatus={changeStatus}
                  Success_Message={Success_Message}
                  Error_Message={Error_Message}
                  askIndex={index}
                  statusClick={statusClick}
                  setStatusClick={setStatusClick}
                  confetti={confetti}
                  isAuthenticated={isAuthenticated}
                  checked={checkedStates[index]} // Use the specific checked state for the ask
                  handleCheckBoxChange={handleCheckBoxChange}
                  fulfilled={
                    parseInt(status) === 2 || parseInt(status) === 3
                      ? true
                      : null
                  }
                  tracking={tracking}
                  setTracking={setTracking}
                  isMobile={isMobile}
                  status={status}
                />
                <Stack spacing={1} direction={"row"}>
                  {group &&
                  group.products &&
                  group.products.length > 1 &&
                  !check_checked_states(index) ? (
                    <>
                      {status === 1 ? (
                        <>
                          <Button
                            color="success"
                            onClick={() => {
                              setOpen(index);
                            }}
                            sx={{ margin: 1, width: "auto" }}
                          >
                            Mark All Fulfilled
                          </Button>
                          <Modal
                            open={open === index}
                            onClose={() => {
                              setOpen(null);
                            }}
                          >
                            <ModalDialog>
                              <Typography
                                sx={{ justifyContent: "center" }}
                                level="body-lg"
                              >
                                Are you sure? this will mark all asks with id:{" "}
                                {group.products[0].id} as fulfilled.
                              </Typography>
                              <Stack
                                spacing={1}
                                direction={"row"}
                                justifyContent={"center"}
                              >
                                <Button
                                  color="success"
                                  sx={{ width: "auto" }}
                                  onClick={() => {
                                    changeMultipleStatus(
                                      user.id,
                                      group.products,
                                      2
                                    );
                                    Email(
                                      group.ask[0].user_id,
                                      user.email,
                                      user.fullName,
                                      user.organization,
                                      createContent(group.products),
                                      "Fulfilled",
                                      group.ask[0].id
                                    );
                                    setOpen(null);
                                  }}
                                >
                                  Yes
                                </Button>
                                <Button
                                  color="danger"
                                  onClick={() => setOpen(null)}
                                  sx={{ width: "auto" }}
                                >
                                  No
                                </Button>
                              </Stack>
                            </ModalDialog>
                          </Modal>
                          <Button
                            color="danger"
                            onClick={() => {
                              setOpenCancelAll(index);
                            }}
                            sx={{ margin: 1, width: "auto" }}
                          >
                            Cancel All
                          </Button>
                          <Modal
                            open={openCancelAll === index}
                            onClose={() => {
                              setOpenCancelAll(null);
                            }}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-desc"
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ModalDialog>
                              <Typography
                                justifyContent={"center"}
                                level="body-lg"
                              >
                                Are you sure? This will cancel all item(s) from
                                ask id: {group.products[0].id}.
                              </Typography>
                              <Stack
                                spacing={1}
                                direction={"row"}
                                justifyContent={"center"}
                              >
                                <Button
                                  color="success"
                                  sx={{ width: "auto" }}
                                  onClick={() => {
                                    changeMultipleStatus(
                                      user.id,
                                      group.products,
                                      0
                                    );
                                    setOpenCancelAll(null);
                                  }}
                                >
                                  Yes
                                </Button>
                                <Button
                                  color="danger"
                                  sx={{ width: "auto" }}
                                  onClick={() => setOpenCancelAll(null)}
                                >
                                  No
                                </Button>
                              </Stack>
                            </ModalDialog>
                          </Modal>
                        </>
                      ) : null}
                    </>
                  ) : null}
                  {status === 1 && show_accept(index) ? (
                    <>
                      <Button
                        color="success"
                        onClick={() => setOpenMark(index)}
                        sx={{ margin: 1, width: "auto" }}
                      >
                        Mark Selected As Fulfilled
                      </Button>
                      <Modal
                        open={openMark === index}
                        onClose={() => {
                          setOpenMark(null);
                        }}
                      >
                        <ModalDialog>
                          <Typography
                            sx={{ justifyContent: "center" }}
                            level="body-lg"
                          >
                            Are you sure? this will mark{" "}
                            {show_num_checked(index)} item(s) as fulfilled from
                            id: {group.products[0].id}.
                          </Typography>
                          <Stack
                            spacing={1}
                            direction={"row"}
                            justifyContent={"center"}
                          >
                            <Button
                              color="success"
                              sx={{ width: "auto" }}
                              onClick={() => {
                                changeMultipleStatus(
                                  user.id,
                                  checkedStates[index],
                                  2
                                );
                                Email(
                                  data[0].ask[0].user_id,
                                  user.email,
                                  user.fullName,
                                  user.organization,
                                  createContent(checkedStates[index]),
                                  "Fulfilled",
                                  data[0].ask[0].id
                                );
                                setOpenMark(null);
                              }}
                            >
                              Yes
                            </Button>
                            <Button
                              color="danger"
                              sx={{ width: "auto" }}
                              onClick={() => setOpenMark(null)}
                            >
                              No
                            </Button>
                          </Stack>
                        </ModalDialog>
                      </Modal>
                      <Button
                        color="danger"
                        sx={{ width: "auto" }}
                        onClick={() => {
                          setOpen(index);
                        }}
                      >
                        Cancel
                      </Button>
                      <Modal
                        open={open === index}
                        onClose={() => {
                          setOpen(null);
                        }}
                      >
                        <ModalDialog>
                          <Typography level="body-lg">
                            Are you sure? This will remove{" "}
                            {show_num_checked(index)} item(s) from id:{" "}
                            {group.products[0].id} from your accepted asks.
                          </Typography>
                          <Stack
                            spacing={1}
                            direction="row"
                            justifyContent="center"
                          >
                            <Button
                              color="success"
                              sx={{ width: "auto" }}
                              onClick={() => {
                                changeMultipleStatus(
                                  user.id,
                                  checkedStates[index],
                                  0
                                );
                                setOpen(null);
                              }}
                            >
                              Yes
                            </Button>
                            <Button
                              color="danger"
                              sx={{ width: "auto" }}
                              onClick={() => setOpen(null)}
                            >
                              No
                            </Button>
                          </Stack>
                        </ModalDialog>
                      </Modal>
                    </>
                  ) : null}
                  {status === 2 && show_tracking(index) ? (
                    <>
                      <Button
                        color="primary"
                        sx={{ width: "auto" }}
                        onClick={() => {
                          setOpen(index);
                        }}
                      >
                        Add/Change Tracking Info
                      </Button>
                      <Modal
                        open={open === index}
                        onClose={() => {
                          setOpen(null);
                        }}
                      >
                        <ModalDialog>
                          <Typography level="body-lg">
                            Add Tracking Info
                          </Typography>
                          <Input
                            type="text"
                            placeholder="Tracking Link"
                            onChange={(e) => setTracking(e.target.value)}
                            sx={{ width: 300 }}
                          />
                          <Stack
                            spacing={1}
                            direction="row"
                            justifyContent="center"
                          >
                            <Button
                              color="success"
                              sx={{ width: "auto" }}
                              onClick={() => {
                                submitTracking(index, group);
                                setOpen(null);
                              }}
                            >
                              Submit
                            </Button>
                            <Button
                              color="danger"
                              sx={{ width: "auto" }}
                              onClick={() => setOpen(null)}
                            >
                              Cancel
                            </Button>
                          </Stack>
                        </ModalDialog>
                      </Modal>
                    </>
                  ) : null}
                </Stack>
              </Card>
            </Stack>
          ))
        )}
      </Stack>
    </Box>
  );
}

export default Accepted;
