import React, {useState, useEffect} from 'react'
import axios from 'axios';
import { Tooltip } from "react-tooltip";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import About from './About';
import HelpIcon from "@mui/icons-material/Help";
import {
    Typography,
    Button,
    Stack,
    Card,
    CircularProgress,
    Modal,
    ModalClose,
    ModalDialog,
    Table,
    Box,
    Sheet,
    Checkbox,
} from "@mui/joy";
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
const { ip } = require("../../ip.js");


function Ask(props) {
    axios.defaults.withCredentials = true;
    const navigate = useNavigate();
    const { id } = useParams();
    const [ask, setAsk] = useState([]);
    const [askProducts, setAskProducts] = useState([]);
    const [data, setData] = useState([]);
    const user = props.user;
    const isAuthenticated = props.isAuthenticated;
    const [checkedStates, setCheckedStates] = useState([]);
    const [openModal, setOpenModal] = useState(null);
    const changeMultipleStatus = props.changeMultipleStatus;
    const statusClick = props.statusClick;
    const setStatusClick = props.setStatusClick;
    const isMobile = props.isMobile;

    console.log('data', data);

    useEffect(() => {
        axios
          .get(`${ip}/asks/ask/${id}/${user.id}/${user.tenant}`)
          .then((response) => {
            if (response.data.message === "Failed") {
              navigate(`/${user.tenant}/home`);
              return;
            }
            setAsk(response.data[0]);
            setAskProducts(response.data[1]);
            setData(response.data[2]);
          })
          .catch((err) => {
            console.log(err);
          });
    }, [statusClick]);

    const groupedData = askProducts.map((product) => {
        const acceptances = data.filter(
        (acceptance) => acceptance.product_name === product.product_name
        );
        return { ...product, acceptances };
    });

    function show_status(status) {
        if (status === "1") {
        return (
          <>
            <Stack direction="row" spacing={1}>
              <Typography color='success'>Accepted</Typography>
              <HelpIcon
                data-tooltip-id="tooltip"
                data-tooltip-content="Donor Has Accepted Request"
                data-tooltip-place="top"
                color="primary"
              />
              <Tooltip id="tooltip" />
            </Stack>
          </>
        );
        } else if (status === "2") {
        return (
          <>
            <Stack direction="row" spacing={1}>
              <Typography color='success'>Fulfilled</Typography>
              <HelpIcon
                data-tooltip-id="tooltip"
                data-tooltip-content="Donor Has Shipped The Items"
                data-tooltip-place="top"
                color="primary"
              />
              <Tooltip id="tooltip" />
            </Stack>
          </>
        );
        } else if (status === "3") {
        return (
          <>
            <Stack direction="row" spacing={1}>
                <Typography color='success'>Received</Typography>
                <HelpIcon
                data-tooltip-id="tooltip"
                data-tooltip-content="You Have Received The Items"
                data-tooltip-place="top"
                color="primary"
                />
                <Tooltip id="tooltip" />
            </Stack>
          </>
        );
        } else {
        return (<Typography color='error'>Not Accepted</Typography>);
        }
    }


    const handleCheckBoxChange = (e, item, status) => {
        setCheckedStates((prevCheckedStates) => {
        if (!prevCheckedStates || prevCheckedStates.length === 0 || prevCheckedStates == {} || prevCheckedStates === null) {
            return [
            {
                id: item.ask_id,
                product_name: item.product_name,
                status,
                acceptance_id: item.acceptance_id,
                quantity: item.accepted_quantity,
            },
            ];
        } else {
            const newStates = [...prevCheckedStates];
            if (e.target.checked) {
            newStates.push({
                id: item.ask_id,
                product_name: item.product_name,
                status,
                acceptance_id: item.acceptance_id,
                quantity: item.accepted_quantity,
            });
            } else {
            // Remove the item from the array based on some unique identifier, here it's `acceptance_id`
            const index = newStates.findIndex(
                (state) => state.acceptance_id === item.acceptance_id
            );
            if (index > -1) {
                newStates.splice(index, 1);
            }
            }
            return newStates;
        }
        });
    };

    function show_modal() {
        if (checkedStates.length > 0) {
            return (
                <>
                <Stack spacing={1} direction={"row"}>
                    <Button
                    color="success"
                    onClick={() => {
                        setOpenModal(true);
                    }}
                    >
                    Mark Received
                    </Button>
                    <Modal
                    open={openModal === true}
                    onClose={() => {
                        setOpenModal(null);
                    }}
                    >
                    <ModalDialog>
                        <Typography alignContent={"center"} level="body-lg">
                        Are you sure you want to mark as received? This will be added
                        to your inventory.
                        </Typography>
                        <Stack spacing={1} direction={"row"} justifyContent={"center"}>
                        <Button
                            color="success"
                            onClick={() => {
                              changeMultipleStatus(user.id, checkedStates, 3, 2);
                              setCheckedStates([]);
                              setStatusClick(!statusClick);
                              setOpenModal(null);
                            }}
                        >
                            Yes
                        </Button>
                        <Button
                            color="danger"
                            onClick={() => {
                            setOpenModal(null);
                            }}
                        >
                            No
                        </Button>
                        </Stack>
                    </ModalDialog>
                    </Modal>
                </Stack>
                </>
            );
        }
    }

    if (!data || data.length === 0) {
        //return <CircularProgress /> in the middle of the web page;
        return (
            <Box
                sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                margin: "auto",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
      <Box sx={{ px: { xs: 2, md: 6 }, pb: 10 }}>
        <ToastContainer />
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              //if user came from orgasks, go back to orgasks
              navigate(-1);
            }}
          >
            <KeyboardBackspaceIcon sx={{ marginRight: "4px" }} /> Back
          </Button>
        </Stack>
        <Card sx={{ mt: 2, p: 2 }}>
          <Typography level="h1">Ask</Typography>
          {ask.map((i, index) => (
            <Typography key={index} variant="h6" component={"span"}>
              <About data={i} user={user} isAuthenticated={isAuthenticated} />
            </Typography>
          ))}
          <Sheet sx={{ overflowX: "auto", width: "100%" }}>
            {" "}
            {/* Ensures horizontal scrolling */}
            <Table
              borderAxis="xBetween"
              aria-label="basic table"
              variant="outlined"
              hoverRow
              sx={{
                minWidth: "1200px", // Minimum width ensures the table has enough space to show all columns on large screens
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: "15%" }}>Product</th>
                  <th>{isMobile ? "Qty" : "Quantity"}</th>
                  <th>Link</th>
                  <th style={{ width: "10%" }}>Accepted Qty</th>
                  <th>Date</th>
                  <th style={{ width: "10%" }}>Accepted User</th>
                  <th style={{ width: "15%" }}>Accepted User Email</th>
                  <th>Org</th>
                  <th>Tracking</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {groupedData.map((product, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td style={{ whiteSpace: "nowrap", textWrap: "wrap" }}>
                        {" "}
                        {/* Ensures no wrapping of text */}
                        <Typography>
                          <b>{product.product_name}</b>
                        </Typography>
                      </td>
                      <td style={{ textAlign: isMobile ? "" : "left" }}>
                        <Typography>
                          <b>{product.quantity}</b>
                        </Typography>
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {" "}
                        {/* Prevent wrapping */}
                        {product.link ? (
                          <Button
                            variant="solid"
                            color="primary"
                            component="a"
                            target="_blank"
                            href={product.link}
                          >
                            <b>Click Here</b>
                          </Button>
                        ) : (
                          <Typography>
                            <b>N/A</b>
                          </Typography>
                        )}
                      </td>
                      <td colSpan={7}>
                        {product.acceptances.length === 0 && (
                          <Typography>No acceptances</Typography>
                        )}
                      </td>
                    </tr>
                    {product.acceptances.map((acceptance, subIndex) => (
                      <tr key={subIndex}>
                        <td colSpan={3}></td>
                        <td>
                          {acceptance.accepted_quantity &&
                          parseInt(acceptance.status) === 2 ? (
                            <Box display="flex" alignItems="center">
                              <Checkbox
                                color="primary"
                                size="sm"
                                label={acceptance.accepted_quantity || 0}
                                checkedStates={
                                  checkedStates &&
                                  checkedStates[subIndex] &&
                                  checkedStates[subIndex].id !== null
                                    ? checkedStates[subIndex]
                                    : false
                                }
                                onChange={(e) => {
                                  handleCheckBoxChange(
                                    e,
                                    acceptance,
                                    acceptance.status
                                  );
                                }}
                              />
                            </Box>
                          ) : (
                            <Box display="flex" alignItems="center">
                              <Typography>
                                {acceptance.accepted_quantity || 0}
                              </Typography>
                            </Box>
                          )}
                        </td>
                        <td>{acceptance?.accepted_date?.split("T")[0]}</td>
                        <td>
                          {/* Ensures no truncation for accepted user name */}
                          {acceptance?.accepted_user_name || "N/A"}
                        </td>
                        <td>
                          {/* Ensures no truncation for accepted user email */}
                          {acceptance?.accepted_user_email || "N/A"}
                        </td>
                        <td>
                          {/* Ensures no truncation for organization */}
                          {acceptance?.accepted_user_organization || "N/A"}
                        </td>
                        <td>
                          {acceptance.tracking ? (
                            <Button
                              variant="solid"
                              color="primary"
                              component="a"
                              target="_blank"
                              href={acceptance.tracking}
                              sx={{ width: "100%" }}
                            >
                              <b>Click Here</b>
                            </Button>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>{show_status(acceptance.status)}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </Sheet>
          {show_modal()}
        </Card>
      </Box>
    );
}

export default Ask