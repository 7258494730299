import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import {
  Card,
  Typography,
  Stack,
  Input,
  Link,
  CircularProgress,
  Container,
  Divider,
  Autocomplete,
  FormLabel,
} from "@mui/joy";
import stripe from "../../images/stripe.svg";
import gyftbayLogo from "../../images/gyftbay_blue_lowercase.png"
const { ip } = require("../../ip.js");

function Payment(props) {
  const user = props.user;
  const navigate = useNavigate();
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const location = useLocation();
  const [donation_amount, set_donation_amount] = useState(
    location?.state?.donation_amount
  );
  const [donationInput, setDonationInput] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [guestOrg, setGuestOrg] = useState("");
  const [organization, setOrganization] = useState(
    location?.state?.organization
  );
  const [checkedItems, setCheckedItems] = useState(
    location?.state?.checkedItems
  );
  const [loading, setLoading] = useState(false);
  const [guestId, setGuestId] = useState(null);
  const Success_Message = props.Success_Message;
  const isAuthenticated = props.isAuthenticated;
  const isMobile = props.isMobile;
  const [admin, setAdmin] = useState({});
  const tenant = props.tenant;
  const tenant_map = {
    dpscd: "Detroit Public Schools",
    wlc: "Walled Lake Central High School",
    Gyftbay: "GyftBay",
  };


  //orgs that can receive donations, either gyftbay or tenant_map
  const donation_receivers = {
    gyftbay: "GyftBay",
    dpscd: "Detroit Public Schools",
    wlc: "Walled Lake Central High School",
    nammy_ryuji: "Nammy and Ryuji",
  };

  function add_fees(amount) {
    return (amount * 1.03 + 0.3).toFixed(2);
  }

  function validate_email(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  // Handler for form submission
  const handleSubmit = () => {
    if (user && isAuthenticated) {
      axios
        .post(`${ip}/guest/changeStatus`, {
          guest_id: user.id,
          checkedItems: checkedItems,
        })
        .then((response) => {
          if (response.data.message === "Accepted by Guest") {
            //set timeout for a second, then navigate to home
            Success_Message(
              "Success! An email has been sent to you with the details of your order."
            );
            setTimeout(() => {
              setLoading(false);
              navigate(`/${tenant}`);
            }, 1000);
          }
        });
    } else {
      //add guest to guest table
      axios
        .post(`${ip}/guest/addGuest`, {
          name: name,
          email: email,
          phoneNumber: phone,
          organization: guestOrg !== "" ? guestOrg : null,
          tenant: tenant,
        })
        .then((response) => {
          if (response.data.id) {
            setGuestId(response.data.id);

            if (!checkedItems) {
              Success_Message("Success!");
              setLoading(false);
              return;
            }

            axios
              .post(`${ip}/guest/changeStatus`, {
                guest_id: response.data.id,
                checkedItems: checkedItems,
                tenant: tenant,
              })
              .then((response) => {
                if (response.data.message === "Accepted by Guest") {
                  //set timeout for a second, then navigate to home
                  Success_Message("Success!");
                  setLoading(false);
                }
              });
          } else {
            //get id of guest
            axios.get(`${ip}/guest/getGuestId/${email}`).then((response) => {
              if (response.data.id) {
                setGuestId(response.data.id);
              }

              if (!checkedItems) {
                Success_Message("Success!");
                setLoading(false);
                return;
              }

              axios
                .post(`${ip}/guest/changeStatus`, {
                  guest_id: response?.data?.id,
                  checkedItems: checkedItems,
                  tenant: tenant,
                })
                .then((response) => {
                  if (response.data.message === "Accepted by Guest") {
                    //set timeout for a second, then navigate to home
                    Success_Message(
                      "Success! An email has been sent to you with the details of your order."
                    );
                    setLoading(false);
                  }
                });
            });
          }
        });
    }
  };

  useEffect(() => {
    if (organization) {
      axios.get(`${ip}/user/admin/${organization}`).then((res) => {
        if (res.data.length === 0) {
          return;
        }
        setAdmin(res.data[0]);
      });
    }
  }, [organization]);

  useEffect(() => {
    axios.get(`${ip}/config-live`).then((r) => {
      const publishableKey = r.data["publishableKey"];
      setStripePromise(loadStripe(publishableKey));
    });
  }, [donationInput]);

  useEffect(() => {
    if (donation_amount || donationInput) {
      if (donationInput && donationInput < 1) {
        return;
      }
      if (!isAuthenticated) {
        if (!email || !name || !validate_email(email)) {
          return;
        }
      }
      if (!organization) {
        return;
      }
      // Only allow donations to GyftBay
      if (organization != "GyftBay") {
        return;
      }
      setLoading(true);
      setTimeout(() => {
        axios
          .post(`${ip}/create-payment-intent-live`, {
            method: "POST",
            amount: add_fees(donation_amount || donationInput),
            email: user?.email || email,
          })
          .then((result) => {
            var clientSecret = result.data["clientSecret"];
            setClientSecret(clientSecret);
            setLoading(false);
          });
      }, 3000);
    } else {
      setClientSecret("");
    }
  }, [donationInput, email, name]);

  function email_donator(
    to_email_id,
    from_email,
    from_name,
    from_organization,
    content,
    status,
    ask_id
  ) {
    axios.get(`${ip}/email/${to_email_id}`).then((response) => {
      if (response.data) {
        emailjs
          .send(
            "service_f5sai5q",
            "template_bs16y39",
            {
              to_email: response.data.email,
              from_email: from_email,
              from_name: from_name,
              from_organization: from_organization || "N/A",
              content: content,
              status: status,
              ask_id: ask_id,
              donation: donationInput,
            },
            "x7LM43rlkJsqov2r7"
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("email sent");
            } else {
              console.log("email not sent");
            }
          });
      }
    });
  }

  return (
    <>
      <ToastContainer />
      <Container maxWidth="md">
        <Card sx={{ padding: isMobile ? 3 : 4, boxShadow: 3 }}>
          <Stack spacing={3}>
            <Stack
              direction={isMobile ? "column" : "row"}
              alignItems="center"
              spacing={2}
              justifyContent={"space-between"}
            >
              <Stack direction="row" spacing={2} alignItems={"center"}>
                <Typography level="h2" component="h2">
                  Donate
                </Typography>
                <img src={stripe} style={{ height: 50 }} alt="Stripe Logo" />
              </Stack>
              <img
                src={gyftbayLogo}
                style={{ height: 100 }}
                alt="GyftBay Logo"
              />
            </Stack>
            {!isAuthenticated || !donation_amount ? (
              <Stack
                direction={"column"}
                spacing={2}
                alignItems={isMobile ? "center" : "flex-start"}
                sx={{ width: "100%" }} // Ensure it doesn't overflow
              >
                <>
                  <Typography
                    sx={{
                      textAlign: isMobile ? "center" : "left", // Center text on mobile
                      wordWrap: "break-word", // Ensure text wraps properly
                      width: "100%", // Constrain the width
                    }}
                  >
                    <i>
                      <b>
                        To donate to Detroit Public Schools Community District,
                        please visit{" "}
                        <a
                          href="https://www.dpsfdn.org/donate/"
                          target="_blank"
                        >
                          https://www.dpsfdn.org/donate/
                        </a>{" "}
                      </b>
                    </i>
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: isMobile ? "center" : "left", // Center text on mobile
                      wordWrap: "break-word", // Ensure text wraps properly
                      width: "100%", // Constrain the width
                    }}
                  >
                    <i>
                      Donating to GyftBay will go directly towards supporting
                      our platform
                    </i>
                  </Typography>
                </>
              </Stack>
            ) : null}
            <Divider />
            <Typography level="h4">Payment Details</Typography>
            {!isAuthenticated && (
              <>
                <Stack
                  spacing={isMobile ? 2 : 3}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Stack spacing={1}>
                    <FormLabel>Full Name *</FormLabel>
                    <Input
                      label="Full Name"
                      placeholder="Enter your full name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      sx={{ fontSize: isMobile ? "14px" : "16px" }}
                      error={!name}
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <FormLabel>Email *</FormLabel>
                    <Input
                      label="Email"
                      type="email"
                      placeholder="Enter your email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      sx={{ fontSize: isMobile ? "14px" : "16px" }}
                      error={!email || !validate_email(email)}
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <FormLabel>Phone *</FormLabel>
                    <Input
                      label="Phone"
                      type="tel"
                      placeholder="Enter your phone number"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      sx={{ fontSize: isMobile ? "14px" : "16px" }}
                      error={!phone}
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <FormLabel>Organization</FormLabel>
                    <Input
                      label="Organization"
                      placeholder="Enter your organization (optional)"
                      value={guestOrg}
                      onChange={(e) => setGuestOrg(e.target.value)}
                      sx={{ fontSize: isMobile ? "14px" : "16px" }}
                      required={false}
                    />
                  </Stack>
                </Stack>
              </>
            )}
            {donation_amount && organization ? (
              <Typography level="body-lg">
                Receiver: <b>{tenant_map[organization] || organization}</b>
              </Typography>
            ) : (
              //dropdown list, either gyftbay or tenant_map
              <Stack direction="row" spacing={2}>
                <Typography level="body-lg">Donate To:</Typography>
                <Autocomplete
                  options={["GyftBay", "Detroit Public Schools"]}
                  onChange={(e, v) => setOrganization(v)}
                  disableClearable={true}
                  error={!organization || organization === ""}
                />
              </Stack>
            )}
            <Stack spacing={2} alignItems="flex-start">
              {donation_amount ? (
                <>
                  <Typography level="body-lg">
                    Before Fees: ${donation_amount}
                  </Typography>
                  <Typography level="body-lg">
                    Total After Stripe Fees: <b>${add_fees(donation_amount)}</b>
                  </Typography>
                </>
              ) : (
                (organization === "GyftBay" || !organization) && (
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography level="body-lg">Amount:</Typography>
                    <Input
                      size="md"
                      placeholder="Enter Amount"
                      value={donationInput}
                      startDecorator="$"
                      onChange={(e) => setDonationInput(e.target.value)}
                      error={!donationInput}
                      fullWidth
                    />
                  </Stack>
                )
              )}
              {donationInput && donationInput < 1 ? (
                <Typography level="body-lg" color="error">
                  Total After Stripe Fees
                </Typography>
              ) : donationInput ? (
                <>
                  <Typography level="body-lg">
                    Total After Stripe Fees: <b>${add_fees(donationInput)}</b>
                  </Typography>
                </>
              ) : null}
            </Stack>
            {loading ? (
              <CircularProgress />
            ) : clientSecret && stripePromise && (organization === "GyftBay" || organization === "Nammy+Ryuji") ? (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm
                  organization={organization}
                  user={user}
                  Success_Message={props.Success_Message}
                  Error_Message={props.Error_Message}
                  donationInput={donationInput}
                  donation_amount={donation_amount}
                  email_donator={email_donator}
                  content={checkedItems}
                  status={"Donation"}
                  ask_id={null}
                  admin={admin || null}
                  add_fees={add_fees}
                  email={email}
                  tenant={user?.tenant || tenant}
                  guest_name={name}
                  changeStatus={handleSubmit}
                />
              </Elements>
            ) : organization === "Detroit Public Schools" ||
              organization === "Walled Lake Central" ? (
              <Typography>
                <b>
                  Please visit{" "}
                  <a href="https://www.dpsfdn.org/donate/" target="_blank">
                    https://www.dpsfdn.org/donate/
                  </a>{" "}
                  to donate to {organization}
                </b>
              </Typography>
            ) : (
              <Typography>
                Enter information above to see payment details
              </Typography>
            )}
          </Stack>
        </Card>
      </Container>
    </>
  );
}

export default Payment;
