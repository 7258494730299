import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Input,
  Typography,
  Button,
  Stack,
  Table,
  Modal,
  ModalClose,
  ModalDialog,
  CircularProgress,
  FormLabel,
} from "@mui/joy";
import axios from "axios";
import { ip } from "../../ip";
import { ConsoleView, isMobile } from "react-device-detect";
import Payment from "./Payment";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import emailjs from "@emailjs/browser";
import DeleteIcon from "@mui/icons-material/Delete";

function GuestCheckout(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const tenant = props.tenant;
  const Success_Message = props.Success_Message;
  const isMobile = props.isMobile;

  // State variables
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [organization, setOrganization] = useState("");
  const [openModal, setOpenModal] = useState(null);
  const [products, setProducts] = useState([]);
  const [addToCardLink, setAddToCardLink] = useState("");
  const [guest_id, setGuestId] = useState(null);
  const [donation_id, setDonationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const [checkedItems, setCheckedItems] = useState(
    location?.state?.checkedItems
  );
  const [itemsForEmail, setItemsForEmail] = useState(null);
  const [estimatedPrice, setEstimatedPrice] = useState(location?.state?.estimatedPrice);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  const generateTableRows = (products, is_order_for_autoshow) => {
    return products
      .map((product) => {
        return `
            <tr>
            <td>${product.id}</td>
            <td>${product.product_name}</td>
            <td><a href="${product.link}">click here</a></td>
            <td>${product.requested_quantity}</td>
            ${!is_order_for_autoshow ? `<td>${product.address}</td>` : ""}
            </tr>
        `;
      })
      .join("");
  };

  const is_order_for_autoshow = (items) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === 182 || items[i].id === 183) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const fetchProductsAndGenerateLink = async () => {
      try {
        // Fetch products
        const res = await axios.get(`${ip}/products`);
        const fetchedProducts = res.data || [];
        setProducts(fetchedProducts);

        // Generate the Add to Cart link
        let link = "https://www.amazon.com/gp/aws/cart/add.html?";
        const productRowsWithAsin = checkedItems.map((row) => {
          const matchingProduct = fetchedProducts.find(
            (product) =>
              product.productName === row.product_name && product.asin
          );
          return matchingProduct
            ? { ...row, asin: matchingProduct.asin }
            : { ...row, asin: null };
        });

        let count = 1;
        productRowsWithAsin.forEach((row, index) => {
          if (row.asin) {
            link += `&ASIN.${count}=${row.asin}&Quantity.${count}=${
              row.requested_quantity
            }`;
            count++;
          }
        });

        setAddToCardLink(link);
      } catch (error) {
        console.error(
          "Error fetching products or generating cart link:",
          error
        );
      }
    };

    if (checkedItems && checkedItems.length > 0) {
      fetchProductsAndGenerateLink();
    }
  }, [checkedItems, ip]);

  function email_guest(id, auto_show) {
    emailjs
      .send(
        "service_f5sai5q",
        auto_show ? "template_esovp8b" : "template_9uyvp7b",
        {
          id: id || donation_id,
          to_email: email,
          guest_name: fullname,
          product_rows: itemsForEmail,
          add_to_cart_link: addToCardLink ? addToCardLink : "N/A",
        },
        "x7LM43rlkJsqov2r7"
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("Email sent to guest");
        } else {
          console.log("Email failed to send");
        }
      });
  }

  function add_fees(amount) {
    return (amount * 1.03 + 0.3).toFixed(2);
  }

  function validate_email(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const handleSubmit = () => {
    if (!fullname || !email || !phone) {
      props.Error_Message("Please fill out all fields!");
      return;
    }
    axios
      .post(`${ip}/guest/addGuest`, {
        name: fullname,
        email: email,
        phoneNumber: phone,
        organization: organization !== "" ? organization : null,
        tenant: tenant,
      })
      .then((guest_id_response) => {
        console.log("guest_id_response: ", guest_id_response);
        if (guest_id_response.data.id) {
          setGuestId(guest_id_response.data.id);

          const donation = {
            amount: 0,
            name: guest_id_response.data.name || fullname,
            email: guest_id_response.data.email || email,
            receiver: tenant || "dpscd",
            tenant: tenant,
            guest_id: guest_id_response.data.id,
          };
          axios
            .post(`${ip}/addDonation`, {
              amount: donation.amount,
              name: donation.name,
              email: donation.email,
              receiver: donation.receiver,
              tenant: donation.tenant,
              guest_id: donation.guest_id,
            })
            .then((res) => {
              axios
                .get(`${ip}/donation/${guest_id_response.data.id}`)
                .then((donation_id_response) => {
                  setDonationId(donation_id_response.data[0].id);
                  axios
                    .post(`${ip}/guest/changeStatus`, {
                      guest_id: guest_id_response.data.id,
                      checkedItems: checkedItems,
                      tenant: tenant,
                      donation_id: donation_id_response.data[0].id,
                    })
                    .then((response) => {
                      if (response.data.message === "Accepted by Guest") {
                        Success_Message(
                          "Success! An email has been sent to you with the details of your order."
                        );
                        email_guest(
                          donation_id_response.data[0].id,
                          is_order_for_autoshow(checkedItems)
                        );
                        setTimeout(() => {
                          setLoading(false);
                          navigate(
                            `/${tenant}/order-details/${donation_id_response.data[0].id}`,
                            {
                              state: {
                                id:
                                  donation_id_response.data[0].id ||
                                  donation_id,
                                to_email: email,
                                guest_name: fullname,
                                product_rows: checkedItems,
                                is_order_for_autoshow:
                                  is_order_for_autoshow(checkedItems),
                                estimatedPrice: estimatedPrice,
                                addToCardLink: addToCardLink,
                              },
                            }
                          );
                        }, 2000);
                      }
                    });
                });
            });
        }
        else {
          //get guest id
          axios.get(`${ip}/guest/getGuestId/${email}`).then((res) => {
            if (res.data.id) {
              setGuestId(res.data.id);
              const donation = {
                amount: 0,
                name: fullname,
                email: email,
                receiver: tenant || "dpscd",
                tenant: tenant,
                guest_id: res.data.id,
              };
              axios
                .post(`${ip}/addDonation`, {
                  amount: donation.amount,
                  name: donation.name,
                  email: donation.email,
                  receiver: donation.receiver,
                  tenant: donation.tenant,
                  guest_id: donation.guest_id,
                })
                .then((dont_care_response) => {
                  axios
                    .get(`${ip}/donation/${res.data.id}`)
                    .then((donation_id_response) => {
                      setDonationId(donation_id_response.data[0].id);
                      axios
                        .post(`${ip}/guest/changeStatus`, {
                          guest_id: res.data.id,
                          checkedItems: checkedItems,
                          tenant: tenant,
                          donation_id: donation_id_response.data[0].id,
                        })
                        .then((response) => {
                          if (response.data.message === "Accepted by Guest") {
                            Success_Message(
                              "Success! An email has been sent to you with the details of your order."
                            );
                            email_guest(
                              donation_id_response.data[0].id,
                              is_order_for_autoshow(checkedItems)
                            );
                            setTimeout(() => {
                              setLoading(false);
                              navigate(
                                `/${tenant}/order-details/${donation_id_response.data[0].id}`,
                                {
                                  state: {
                                    id:
                                      donation_id_response.data[0].id ||
                                      donation_id,
                                    to_email: email,
                                    guest_name: fullname,
                                    product_rows: checkedItems,
                                    is_order_for_autoshow:
                                      is_order_for_autoshow(checkedItems),
                                    estimatedPrice: estimatedPrice,
                                    addToCardLink: addToCardLink,
                                  },
                                }
                              );
                            }, 2000);
                          }
                        });
                    });
                });
            }
          });
        }
      });
  };

  console.log("checkedItems: ", checkedItems);

  useEffect(() => {
    setItemsForEmail(
      generateTableRows(checkedItems, is_order_for_autoshow(checkedItems))
    );
  }, [checkedItems]);

  return (
    <Box
      sx={{
        width: isMobile ? "90%" : "70%",
        margin: "0 auto",
        padding: isMobile ? "16px" : "32px",
        border: "1px solid",
        borderColor: "neutral.outlinedBorder",
        borderRadius: "md",
        boxShadow: "sm",
        backgroundColor: "background.body",
      }}
    >
      <ToastContainer />
      <Typography
        level="h4"
        textAlign="center"
        sx={{ marginBottom: isMobile ? "16px" : "24px" }}
      >
        Guest Checkout
      </Typography>
      <Stack
        spacing={isMobile ? 2 : 3}
        sx={{
          width: "100%",
        }}
      >
        <Typography>
          {is_order_for_autoshow(checkedItems) ? (
            <i>
              <b>
                * By clicking "Checkout", You are assuming responsibility for
                purchasing these items and bringing/delivering them to the
                Detroit Autoshow. Clicking "Checkout" does not require input of
                credit card information *
              </b>
            </i>
          ) : (
            <i>
              <b>
                * By clicking "Checkout", You are assuming responsibility for
                purchasing these items and delivering them to the specified
                address. Clicking "Checkout" does not require input of credit
                card information *
              </b>
            </i>
          )}
        </Typography>
        <Stack spacing={1}>
          <FormLabel>Full Name *</FormLabel>
          <Input
            label="Full Name"
            placeholder="Enter your full name"
            required
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
            sx={{ fontSize: isMobile ? "14px" : "16px" }}
            error={!fullname}
          />
        </Stack>
        <Stack spacing={1}>
          <FormLabel>Email *</FormLabel>
          <Input
            label="Email"
            type="email"
            placeholder="Enter your email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ fontSize: isMobile ? "14px" : "16px" }}
            error={!email || !validate_email(email)}
          />
        </Stack>
        <Stack spacing={1}>
          <FormLabel>Phone *</FormLabel>
          <Input
            label="Phone"
            type="tel"
            placeholder="Enter your phone number"
            required
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            sx={{ fontSize: isMobile ? "14px" : "16px" }}
            error={!phone}
          />
        </Stack>
        <Stack spacing={1}>
          <FormLabel>Organization</FormLabel>
          <Input
            label="Organization"
            placeholder="Enter your company or org (optional)"
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
            sx={{ fontSize: isMobile ? "14px" : "16px" }}
            required={false}
          />
        </Stack>
        <Table
          sx={{
            textAlign: "left",
            marginTop: "16px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "30%" }}>Item</th>
              <th>Quantity</th>
              <th>Shipping Address</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {checkedItems.map((item) => (
              <tr key={item.id}>
                <td>{item.product_name}</td>
                <td>{item.requested_quantity}</td>
                <td>{item.address}</td>
                <td>
                  <DeleteIcon
                    color="danger"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      //if size is 1, navigate back to previous page
                      if (checkedItems.length === 1) {
                        navigate(-1);
                      } else {
                        //remove item from checkedItems
                        setCheckedItems(
                          checkedItems.filter((checkedItem) => {
                            return checkedItem.id !== item.id;
                          })
                        );
                        //update estimated price using product price from product array, limit to 2 decimal places
                        let newEstimatedPrice = estimatedPrice;
                        products.forEach((product) => {
                          if (product.productName === item.product_name) {
                            newEstimatedPrice -= product.price * item.requested_quantity;
                          }
                        });
                        setEstimatedPrice(Number(newEstimatedPrice).toFixed(2));
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Typography
          sx={{
            marginTop: "16px",
            marginBottom: "16px",
            fontSize: isMobile ? "14px" : "16px",
          }}
        >
          <b>Estimated Total: ${estimatedPrice}</b>
        </Typography>
        <Button
          type="submit"
          variant="solid"
          color="success"
          size={isMobile ? "sm" : "md"}
          sx={{
            marginTop: isMobile ? "16px" : "24px",
            padding: isMobile ? "8px 16px" : "12px 24px",
          }}
          onClick={() => {
            if (fullname && email && phone) {
              setOpenModal(true);
            }
          }}
        >
          {loading ? <CircularProgress /> : "Checkout"}
        </Button>
        <Modal open={openModal === true}>
          <ModalDialog>
            <Typography
              sx={{ justifyContent: "center", alignItems: "flex-start" }}
              level="body-lg"
            >
              Are you sure you want to accept?
            </Typography>
            <Stack spacing={2} direction="row" justifyContent={"center"}>
              <Button
                color="success"
                onClick={() => {
                  setOpenModal(false);
                  setLoading(true);
                  if (!fullname || !email || !phone) {
                    props.Error_Message("Please fill out all fields!");
                    return;
                  }

                  handleSubmit();
                }}
              >
                Yes
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                No
              </Button>
            </Stack>
          </ModalDialog>
        </Modal>
      </Stack>
    </Box>
  );
}

export default GuestCheckout;
