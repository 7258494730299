import { React } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Card, Stack, Table, Button } from "@mui/joy";

function OrderDetails(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const tenant = props.tenant;

  const {
    id,
    to_email,
    guest_name,
    product_rows,
    is_order_for_autoshow,
    estimatedPrice,
    addToCardLink
  } = location.state || {};

  return (
    <Box
      sx={{
        maxWidth: "800px",
        margin: "0 auto",
        padding: "24px",
        border: "1px solid",
        borderColor: "neutral.outlinedBorder",
        borderRadius: "lg",
        boxShadow: "lg",
        backgroundColor: "background.body",
      }}
    >
      <Typography
        level="h3"
        textAlign="center"
        sx={{
          marginBottom: "16px",
          fontWeight: "bold",
          color: "primary.700",
        }}
      >
        DPSCD{is_order_for_autoshow ? "/2025 Auto Show" : ""} - Order ID #{id}
      </Typography>

      <Card
        variant="outlined"
        sx={{
          padding: "16px",
          marginBottom: "24px",
          backgroundColor: "neutral.softBg",
        }}
      >
        <Typography level="body1" sx={{ marginBottom: "8px" }}>
          <b>Guest Name:</b> {guest_name}
        </Typography>
        <Typography level="body1">
          <b>Email:</b> {to_email}
        </Typography>
      </Card>

      <Card
        variant="outlined"
        sx={{
          padding: "16px",
          marginBottom: "24px",
          backgroundColor: "neutral.softBg",
        }}
      >
        <Typography
          level="h5"
          sx={{ marginBottom: "16px", fontWeight: "bold" }}
        >
          Items to Order
        </Typography>
        <Table
          sx={{
            width: "100%",
            borderCollapse: "collapse",
            "& th, & td": {
              padding: "8px",
              borderBottom: "1px solid",
              borderColor: "neutral.outlinedBorder",
              textAlign: "left",
            },
            "& th": { backgroundColor: "neutral.softHoverBg" },
          }}
        >
          <thead>
            <tr>
              <th>Item</th>
              <th>Quantity</th>
              <th>Link</th>
              <th>Shipping Address</th>
            </tr>
          </thead>
          <tbody>
            {product_rows.map((row, index) => (
              <tr key={index}>
                <td>{row.product_name}</td>
                <td>{row.requested_quantity}</td>
                <td>
                  {row.link ? (
                    <a
                      href={row.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>{row.address}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Typography level="h5" sx={{ marginTop: "16px", fontWeight: "bold" }}>
          Estimated Total: ${estimatedPrice}
        </Typography>
        <Typography
          level="body2"
          sx={{ marginTop: "8px", color: "text.secondary" }}
        >
          *Please note that the total price is an estimate and may vary based on
          the final price of the items.
        </Typography>
      </Card>

      <Card
        variant="outlined"
        sx={{
          padding: "16px",
          backgroundColor: "neutral.softBg",
        }}
      >
        <Typography level="body1" sx={{ lineHeight: 1.6 }}>
          {is_order_for_autoshow ? (
            <>
              You can either:
              <ol>
                <li>
                  Deliver them to the DPSCD warehouse at
                  <b> 2785 E Grand Blvd, Detroit MI 48211</b>
                </li>
                <li>
                  Bring them with you when you attend the 2025 Auto Show at
                  Huntington Place and drop them off at the Car/Truck of the
                  Year.
                </li>
              </ol>
            </>
          ) : null}
          Please include "<b>Order ID #: {id}</b>" in the "full name" field of your
          purchase at checkout. This will help us match the order and sort the items effectively.
          <Typography
            level="body2"
            sx={{ marginBottom: "16px", color: "text.secondary" }}
          >
            Click the link below to automatically add all accepted items to your
            Amazon cart. Review your cart and complete your purchase on Amazon.
          </Typography>
          <br />
          <br />
          <a
            href={addToCardLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "primary.500" }}
          >
            Add Items to Amazon Cart
          </a>
          <br />
          <br />
          We have sent this order confirmation to your email address as well if
          you wish to order these items at a later date. Please check your
          spam/junk folder if you do not see the email in your inbox. If you
          have any questions or need assistance, feel free to reach us at
          <a href="mailto:admin@gyftbay.com"> admin@gyftbay.com</a>.
          <br />
          <br />
          We look forward to serving you again soon!
        </Typography>
      </Card>

      <Stack direction="row" justifyContent="center" sx={{ marginTop: "24px" }}>
        <Button
          variant="solid"
          color="primary"
          onClick={() => navigate(`/${tenant}`)}
          sx={{ width: "200px" }}
        >
          Back to Home
        </Button>
      </Stack>
    </Box>
  );
}

export default OrderDetails;
