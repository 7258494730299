import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect, React } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CssVarsProvider } from '@mui/joy/styles';
import { AspectRatio, Button, Input, FormLabel, FormControl, Typography, Sheet, Stack, Box } from '@mui/joy';
import ReactGA from 'react-ga';
import healthhub from '../images/healthhub.png';
import gyftbaylogo from '../images/gyftbay_blue_lowercase.png';
import {ip} from '../ip.js';

function Login(props) {
    axios.defaults.withCredentials = true;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    //const user = props.user;
    const setUser = props.setUser;
    //const isAuthenticated = props.isAuthenticated;
    const setIsAuthenticated = props.setIsAuthenticated;
    const [err, setErr] = useState('');
    const navigate = useNavigate();
    let location = useLocation();
    const tenant = props.tenant;
    const setTenant = props.setTenant;
    const isMobile = props.isMobile;

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        const checkLoginStatus = async () => {
          try {
            const response = await axios.get(`${ip}/login/login`, {
              withCredentials: true,
            });
            if (response.data.loggedIn === true) {
              setIsAuthenticated(true);
              setUser(response.data.user);
              navigate(1);
            } else {
              // User is not authenticated, redirect to login
              navigate(`/${tenant}/login`, { replace: true });
            }
          } catch (error) {
            console.error("Error checking login status:", error);
          }
        };
        checkLoginStatus();
        //showLoggedOut();
    }, []);

    const handleLogin = (e) => {
        e.preventDefault();
        axios
          .post(
            `${ip}/login/login`,
            {
              email: email,
              password: password,
              tenant: tenant,
            },
            { withCredentials: true }
          )
          .then((response) => {
            if (response.data.message) {
              setErr(response.data.message);
              Error_Message(response.data.message);
            } else {
              if (response.data) {
                setUser(response.data[0]);
                setIsAuthenticated(true);
                setTenant(response.data[0].tenant);
                navigate(`/${response.data[0].tenant}/landingPage`, { state: { toast: true, loggedIn: true }});
              }
            }
          });
    }

    const Error_Message = (message) => {
        toast.error(message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
        });
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLogin(e);
        }
    };

    function showLoggedOut() {
      if (location && location.state && location.state.loggedOut) {
        toast.success("Logged Out Successfully!", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          toastId: "ask_created",
          onClose: () => {
            location.state.loggedOut = false;
          },
        });
      }
    }

    return (
      <CssVarsProvider>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          gap={2}
          sx={{ display: "block", margin: "auto" }}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <img
            src={healthhub}
            loading="lazy"
            alt=""
            style={{
              width: isMobile ? "40%" : "20%",
              height: "auto",
              display: "block",
              margin: "auto",
            }}
          />
          <img
            src={gyftbaylogo}
            loading="lazy"
            alt=""
            style={{
              width: isMobile ? "40%" : "20%",
              height: "auto",
              display: "block",
              margin: "auto",
            }}
          />
        </Stack>
        <Box
          sx={{
            minHeight: isMobile ? "900px" : 700,
            alignContent: "center",
            display: "flex",
            flexDirection: "column",
            gap: 3,
            overflowX: "auto",
            alignItems: "center",
            padding: "0",
            height: "100%",
          }}
        >
          <main>
            <Sheet
              sx={{
                width: 300,
                mx: "auto", // margin left & right
                my: 4, // margin top & bottom
                py: 3, // padding top & bottom
                px: 2, // padding left & right
                display: "flex",
                flexDirection: "column",
                gap: 2,
                borderRadius: "sm",
                boxShadow: "md",
              }}
              variant="outlined"
            >
              {showLoggedOut()}
              <div>
                <ToastContainer />
                <Typography level="h4" component="h1">
                  <b>Welcome!</b>
                </Typography>
                <Typography level="body-sm">Sign in to continue.</Typography>
              </div>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  // html input attribute
                  name="email"
                  type="email"
                  placeholder="johndoe@email.com"
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input
                  // html input attribute
                  name="password"
                  type="password"
                  placeholder="password"
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyPress}
                  autoComplete="current-password"
                />
              </FormControl>
              <Button sx={{ mt: 1 }} onClick={handleLogin} type="submit">
                Log in
              </Button>
              {/* <Typography
                        endDecorator={<Link to={'/signup'}>Sign up</Link>}
                        fontSize="sm"
                        sx={{ alignSelf: 'center' }}
                    >
                        Don&apos;t have an account?
                    </Typography> */}
            </Sheet>
          </main>
        </Box>
      </CssVarsProvider>
    );
}

export default Login