import React, { useState, useEffect } from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import { Card, CardCover, CardContent, Typography, Box, Stack } from "@mui/joy";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ip } from "../ip";
import DrivenByServiceBanner from "../images/DrivenByServiceBanner.png";
import Lightbox from "yet-another-react-lightbox";
import Inline from "yet-another-react-lightbox/plugins/inline";
import gyftbay_light_blue_lowercase from "../images/gyftbay_light_blue_lowercase.png";
import "yet-another-react-lightbox/styles.css";

function Entry(props) {
  const { tenant, showLoggedOut, isMobile } = props;
  const navigate = useNavigate();
  const [weddingPhotos, setWeddingPhotos] = useState([]);

  useEffect(() => {
    axios.defaults.withCredentials = true;
    const checkLoginStatus = async () => {
      try {
        const response = await axios.get(`${ip}/login/login`, {
          withCredentials: true,
        });
        if (response.data.loggedIn === true) {
          navigate(`/${tenant}/profile`);
        }
      } catch (error) {
        console.error("Error checking login status:", error);
      }
    };
    checkLoginStatus();
    showLoggedOut();
  }, [showLoggedOut, navigate, tenant]);

  useEffect(() => {
    if (tenant === "nammy-ryuji-wedding") {
      axios.get(`${ip}/wedding-photos`).then((res) => {
        setWeddingPhotos(res.data);
      });
    }
  }, [tenant]);

  const buttons = [
    {
      label: "Browse Items to Donate/Gift",
      path: `/${tenant}/browse`,
    },
    {
      label: "Contact Us!",
      path: `/${tenant}/contact`,
    },
    {
      label: "Monetary Donations",
      path: `/${tenant}/donate`,
    },
    {
      label: "About GyftBay",
      path: `/${tenant}/about-gyftbay`,
    },
  ];

  return (
    <CssVarsProvider>
      <img
        src={gyftbay_light_blue_lowercase}
        loading="lazy"
        alt="GyftBay Logo"
        style={{
          display: "block",
          width: "100%",
          height: "auto",
          maxWidth: "400px",
          margin: "auto",
          marginBottom: "-3rem",
        }}
      />
      <Box
        sx={{
          animation: "fadeIn 2s ease-in-out",
          "@keyframes fadeIn": {
            from: { opacity: 0 },
            to: { opacity: 1 },
          },
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
          padding: "2%",
        }}
      >
        <ToastContainer />
        {tenant === "dpscd" && (
          <img
            src={DrivenByServiceBanner}
            loading="lazy"
            alt="Driven by Service Banner"
            style={{
              display: "block",
              width: "100%",
              height: "auto",
              maxWidth: "1200px",
            }}
          />
        )}
        <Typography
          level="h1"
          sx={{
            fontSize: { xs: "2rem", sm: "3rem", md: "4rem" },
            textAlign: "center",
            marginBottom: "1rem",
          }}
        >
          Welcome To GyftBay!
        </Typography>
        <Typography
          level="h3"
          sx={{
            fontSize: { xs: "1.25rem", sm: "1.5rem", md: "2rem" },
            textAlign: "center",
            marginBottom: "2rem",
          }}
        >
          Eliminate the guesswork in gift giving
        </Typography>
        {tenant === "nammy-ryuji-wedding" && (
          <Box
            sx={{
              width: "100%",
              maxWidth: "1200px",
              aspectRatio: "3 / 2",
              marginBottom: "2rem",
              alignSelf: "center",
            }}
          >
            <Card sx={{ minWidth: 300, height: "100%", flexGrow: 1 }}>
              <CardCover>
                <Lightbox
                  slides={weddingPhotos.map((photo) => ({ src: photo.url }))}
                  plugins={[Inline]}
                  carousel={{
                    padding: 0,
                    spacing: 0,
                    imageFit: "cover",
                  }}
                  inline={{
                    style: {
                      width: "100%",
                      height: "100%",
                    },
                  }}
                />
              </CardCover>
              {/* <CardContent>
                <Typography
                  level="h3"
                  color="black"
                  sx={{ fontWeight: "lg", mt: { xs: 12, sm: 98  } }}
                >
                  Celebrating Nammy and Ryuji!
                </Typography>
              </CardContent> */}
            </Card>
          </Box>
        )}
        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="center"
          sx={{ flexWrap: "wrap", gap: "1rem" }}
        >
          {buttons.map((button, index) => (
            <Card
              key={index}
              variant="outlined"
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: isMobile ? "250px" : { xs: "150px", sm: "200px", md: "250px" },
                height: { xs: "75px", sm: "100px", md: "120px" },
                padding: "20px",
                transition: "transform 0.3s, border 0.3s",
                "&:hover": {
                  borderColor: theme.vars.palette.primary.outlinedHoverBorder,
                  transform: "scale(1.05)",
                },
                ":hover": {
                  cursor: "pointer",
                },
              })}
              onClick={() => navigate(button.path)}
            >
              <Typography
                level="title-lg"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {button.label}
              </Typography>
            </Card>
          ))}
        </Stack>
      </Box>
    </CssVarsProvider>
  );
}

export default Entry;
