import React, { useState, useEffect } from "react";
import { Typography, Box, Grid, Card, Avatar, CircularProgress, Link } from "@mui/joy";
import axios from "axios";
import { ip } from "../ip.js";
import gyftbay_yellow_logo from "../images/gyftbay_logo_yellow.png";
import gyftbay_blue_logo from "../images/gyftbay_logo_blue.png";
import gyftbay_black_logo from "../images/gyftbay_logo_black.png";
import gyftbay_light_blue_logo from "../images/gyftbay_logo_light_blue.png";
import gyftbay_light_blue_lowercase from "../images/gyftbay_light_blue_lowercase.png";

function AboutUs(props) {
  const [data, setData] = useState([]);
  const [visuals, setVisuals] = useState([]);
  const [loading, setLoading] = useState(true);
  const tenant = props.tenant;
  const isMobile = props.isMobile;

  useEffect(() => {
    setLoading(true);
    axios.get(`${ip}/team-images`).then((res) => {
      setData(res.data);
    });
    axios.get(`${ip}/visuals`).then((res) => {
      const visualsData = res.data || [];
      setVisuals(visualsData);
      setLoading(false);
    });
  }, []);

  const visualCollageSettings = {
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
  };

const sections = [
  {
    title: "Who We Are",
    text: (
      <Typography>
        GyftBay is your trusted platform for on-demand gift-giving, connecting
        donors with organizations in need. We simplify the gifting process with
        satisfaction, simplicity, and scalability at our core. Whether you’re
        looking to support a cause or fulfill a heartfelt request, GyftBay
        ensures every gift makes an impact.
      </Typography>
    ),
    image: gyftbay_light_blue_lowercase,
    caption: "DPSCD Team working to assemble tables",
  },
  {
    title: "Our Story",
    text: (
      <Typography>
        We saw a need for reducing guesswork in gifting. Our kids’
        schoolteachers had no means of broadcasting their specific classroom
        needs to parents willing to give. This prompted us to start this
        tech-driven marketplace as a means of removing the guesswork in gifting.
      </Typography>
    ),
    image: gyftbay_blue_logo,
    caption: "DPSCD Team unpacking boxes of food and water",
  },
  {
    title: "Community Impact",
    text: (
      <Typography>
        Fulfilling the specific Asks of teachers in your community schools has
        an immediate impact on the student’s learning experiences and outcomes.
        Teachers at the Detroit Public School Community District have broadcast
        their specific classroom Asks on our{" "}
        <a href={`/${tenant}/browse`}>browse</a> page.
      </Typography>
    ),
    image: gyftbay_light_blue_lowercase,
    caption: "DPSCD Team receiving pallates of donations",
  },
  {
    title: "Flexible Donation Options",
    text: (
      <Typography>
        We offer various ways for businesses to contribute to their local
        schools by catering to different budgets and preferences. This includes{" "}
        <a href={`/${tenant}/donate`}>monetary donations</a> as well as
        fulfilling specific <a href={`/${tenant}/browse`}>in-kind Asks</a> for
        teacher’s classroom needs.
      </Typography>
    ),
    image: gyftbay_blue_logo,
    caption: "Community Driven Service",
  },
];

  return (
    <Box
      sx={{
        padding: isMobile ? "1rem" : "2rem",
        maxWidth: "1200px",
        margin: "0 auto",
        overflowX: "hidden",
      }}
    >
      <Box sx={{ textAlign: "center", marginBottom: "3rem" }}>
        <Typography
          level="h1"
          sx={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: isMobile ? "1.8rem" : "2.5rem",
          }}
        >
          About Us
        </Typography>
        <Typography
          level="body1"
          sx={{
            color: "text.secondary",
            maxWidth: isMobile ? "100%" : "800px",
            margin: "0 auto",
            lineHeight: 1.8,
          }}
        >
          Learn more about GyftBay, our mission, our story, and the impact we
          are making in the community.
        </Typography>
      </Box>

      {sections.map((section, index) => (
        <Grid
          container
          spacing={isMobile ? 2 : 3}
          alignItems="center"
          sx={{ marginBottom: "3rem" }}
          key={index}
          direction={index % 2 === 0 || isMobile ? "row" : "row-reverse"}
        >
          <Grid item xs={12} md={6}>
            <Box sx={{ padding: isMobile ? "0.5rem" : "1rem" }}>
              <Typography
                level="h2"
                sx={{
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  fontSize: isMobile ? "1.5rem" : "2rem",
                }}
              >
                {section.title}
              </Typography>
              <Typography
                level="body1"
                sx={{
                  color: "text.secondary",
                  lineHeight: 1.6,
                  fontSize: isMobile ? "0.9rem" : "1rem",
                }}
              >
                {section.text}
              </Typography>
            </Box>
          </Grid>
          {!isMobile && (
            <Grid item xs={12} md={6}>
              {loading && <CircularProgress />}
              <Box
                component="img"
                src={section.image}
                sx={{
                  width: "auto",
                  height: "400px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              />
            </Grid>
          )}
        </Grid>
      ))}

      <Box sx={{ marginBottom: "2rem" }}>
        <Typography
          level="h2"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "2rem",
            fontSize: isMobile ? "1.5rem" : "2rem",
          }}
        >
          Meet Our Team
        </Typography>
        <Grid container spacing={isMobile ? 1 : 2}>
          {data.map((member, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  padding: "1.5rem",
                  textAlign: "center",
                  margin: isMobile ? "0.5rem 0" : "0 auto",
                }}
              >
                <Avatar
                  src={member.image_url}
                  sx={{
                    width: isMobile ? 75 : 100,
                    height: isMobile ? 75 : 100,
                    margin: "0 auto",
                    marginBottom: "1rem",
                  }}
                />
                <Typography
                  level="h3"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: "0.5rem",
                    fontSize: isMobile ? "1rem" : "1.25rem",
                  }}
                >
                  {member.name}
                </Typography>
                <Typography
                  level="body2"
                  sx={{
                    color: "text.secondary",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                  }}
                >
                  {member.role}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default AboutUs;
