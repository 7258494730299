import * as React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { CardCover, CardContent, Button, Box, Card, Stack } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { ip } from "../../ip";

export default function Training(props) {
  const videoRef = React.useRef(null);
  const user = props.user;
  const isMobile = props.isMobile;
  const [trainingVids, setTrainingVids] = useState([]);

  const video_title_map = {
    "accept_ask.mp4": "Accepting an Ask",
    "mark_fulfilled.mp4": "Marking an Ask as Fulfilled",
    "create_ask.mp4": "Creating an Ask",
    "training_video.mp4": "Complete Workflow",
  };

  useEffect(() => {
    // Get training videos
    axios.get(`${ip}/training-vids`).then((res) => {
      setTrainingVids(res.data);
    });
  }, []);

  const handleVideoClick = (videoRef) => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const handleFullScreen = (videoRef) => {
    const video = videoRef.current;
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) {
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) {
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) {
      video.msRequestFullscreen();
    }
  };

  return (
    <Box
      component="ul"
      sx={{
        display: "flex",
        gap: 2,
        flexWrap: "wrap",
        p: 0,
        m: 0,
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "50px",
      }}
    >
      <Stack
        display={"flex"}
        spacing={2}
        useFlexGap={true}
        sx={{
          borderBottom: "0",
          paddingBottom: "0 !important",
          marginLeft: "10px",
        }}
      >
        <Stack>
          <Typography level="h1">Training Videos</Typography>
        </Stack>
        {user && (user.account_type === "NPO" || user.account_type === "member")
          ? // Render only videos with keys "accept_ask" or "mark_fulfilled" for NPO and member users
            trainingVids
              .filter(
                (vid) =>
                  vid.key === "accept_ask.mp4" ||
                  vid.key === "mark_fulfilled.mp4"
              )
              .map((vid) => (
                <Card
                  component="li"
                  key={vid.key}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 300,
                    maxWidth: "75%",
                  }}
                >
                  <Typography level="h4">{video_title_map[vid.key]}</Typography>
                  <Card
                    component="li"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: isMobile ? "100%" : 300,
                      maxWidth: "75%",
                    }}
                  >
                    <CardCover>
                      <video
                        ref={videoRef}
                        muted
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={() => handleVideoClick(videoRef)}
                      >
                        <source src={vid.url} type="video/mp4" />
                      </video>
                    </CardCover>
                    <CardContent>
                      <Typography
                        level="body-lg"
                        fontWeight="lg"
                        textColor="#fff"
                        mt={{ xs: 12, sm: 18 }}
                      >
                        Video
                      </Typography>
                      <Button
                        size="sm"
                        onClick={() => handleFullScreen(videoRef)}
                      >
                        <PlayArrowIcon />
                      </Button>
                    </CardContent>
                  </Card>
                </Card>
              ))
          : // Render all videos for other users
            trainingVids.filter(
                (vid) =>
                  vid.key !== "accept_ask.mp4" &&
                  vid.key !== "mark_fulfilled.mp4"
              ).map((vid) => (
              <Card
                component="li"
                key={vid.key}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: 300,
                  maxWidth: "75%",
                }}
              >
                <Typography level="h4">{video_title_map[vid.key]}</Typography>
                <Card
                  component="li"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: isMobile ? "100%" : 300,
                    maxWidth: "75%",
                  }}
                >
                  <CardCover>
                    <video
                      ref={videoRef}
                      muted
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                      onClick={() => handleVideoClick(videoRef)}
                    >
                      <source src={vid.url} type="video/mp4" />
                    </video>
                  </CardCover>
                  <CardContent>
                    <Typography
                      level="body-lg"
                      fontWeight="lg"
                      textColor="#fff"
                      mt={{ xs: 12, sm: 18 }}
                    >
                      Video
                    </Typography>
                    <Button
                      size="sm"
                      onClick={() => handleFullScreen(videoRef)}
                    >
                      <PlayArrowIcon />
                    </Button>
                  </CardContent>
                </Card>
              </Card>
            ))}
        <Stack>
          <Typography level="body-lg">
            For further questions, contact us at <b>gyftbayalerts@gmail.com</b>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
