import React, { useEffect } from "react";
import { Box, Card, Typography, Stack } from "@mui/joy/";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function LandingPage(props) {
  const { user } = props;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.toast) {
      toast.success("Logged In Successfully!", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        onClose: () => {
          location.state.toast = false;
        },
      });
    }
  }, [location]);

  // Button configurations
  const buttons = [
    { label: "Home", path: `/${user.tenant}/home` },
    { label: "My Account", path: `/${user.tenant}/profile` },
    ...(user.account_type === "School" || user.account_type === "Admin"
      ? [
          { label: "Create Ask", path: `/${user.tenant}/create` },
          { label: "Help", path: `/${user.tenant}/training` },
        ]
      : []),
  ];

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "2rem",
        background: "#f9f9f9",
      }}
    >
      <ToastContainer />
      <Typography
        level="h1"
        component="h1"
        sx={{
          marginBottom: "2rem",
          fontWeight: "bold",
          textAlign: "center",
          fontSize: "2rem",
        }}
      >
        Welcome to GyftBay!
      </Typography>

      <Stack spacing={3} sx={{ width: "100%", maxWidth: "600px" }}>
        {buttons.map((button, index) => (
          <Card
            key={index}
            variant="outlined"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80px",
              transition: "transform 0.3s, border 0.3s",
              background: "#ffffff",
              "&:hover": {
                borderColor: theme.vars.palette.primary.outlinedHoverBorder,
                transform: "scale(1.05)",
              },
              ":hover": {
                cursor: "pointer",
              },
            })}
            onClick={() => navigate(button.path)}
          >
            <Typography
              level="h5"
              sx={{
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              {button.label}
            </Typography>
          </Card>
        ))}
      </Stack>
    </Box>
  );
}
