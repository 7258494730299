import React, { useState, useEffect } from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import { Card, Typography, Box, Stack } from "@mui/joy";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ip } from "../ip";
import DrivenByServiceBanner from "../images/DrivenByServiceBanner.png";

function Entry(props) {
  const { tenant, showLoggedOut, isMobile } = props;
  const navigate = useNavigate();
  const [visuals, setVisuals] = useState([]);

  useEffect(() => {
    axios.defaults.withCredentials = true;
    const checkLoginStatus = async () => {
      try {
        const response = await axios.get(`${ip}/login/login`, {
          withCredentials: true,
        });
        if (response.data.loggedIn === true) {
          navigate(`/${tenant}/profile`);
        }
      }
      catch (error) {
        console.error("Error checking login status:", error);
      }
    }
    checkLoginStatus();
    showLoggedOut();
  }, [showLoggedOut, navigate, tenant]);

  // Button configurations
  const buttons = [
    {
      label: "Browse Items to Donate",
      path: `/${tenant}/browse`,
    },
    {
      label: "Contact Us!",
      path: `/${tenant}/contact`,
    },
    {
      label: "Monetary Donations",
      path: `/${tenant}/donate`,
    },
    {
      label: "About Gyftbay",
      path: `/${tenant}/about-gyftbay`,
    },
  ];

  return (
    <CssVarsProvider>
      <Box
        sx={{
          minHeight: isMobile ? '900px' : 750,
          alignContent: "center",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          overflowX: "auto",
          alignItems: "center",
          padding: "0",
          height: "100%",
        }}
      >
        <img
          src={DrivenByServiceBanner}
          loading="lazy"
          alt=""
          style={{ display: "block", width: "100%", height: "auto" }}
        />
        <ToastContainer />
        <Stack spacing={4} direction="column" alignItems="center">
          <Typography level="h1">Welcome To GyftBay!</Typography>
          <Typography level="h3">Eliminate the guesswork in gift giving</Typography>
        </Stack>
        <Stack spacing={2} direction={isMobile ? "column" : "row"} alignItems="center">
          {buttons.map((button, index) => (
            <Card
              key={index}
              variant="outlined"
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                width: "200px",
                height: "100px",
                padding: "20px",
                gap: "16px",
                transition: "transform 0.3s, border 0.3s",
                "&:hover": {
                  borderColor: theme.vars.palette.primary.outlinedHoverBorder,
                  transform: "translateY(-4px) scale(1.05)",
                },
                ":hover": {
                  cursor: "pointer",
                },
              })}
              onClick={() => navigate(button.path)}
            >
              <Typography
                level="title-lg"
                sx={{
                  fontSize: "1.25rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {button.label}
              </Typography>
            </Card>
          ))}
        </Stack>
      </Box>
    </CssVarsProvider>
  );
}

export default Entry;
