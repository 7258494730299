import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Stack, Card, Button } from "@mui/joy";
import HomeIcon from "@mui/icons-material/Home";
import CelebrationIcon from "@mui/icons-material/Celebration";
import gyftbay_logo_blue from "../../images/gyftbay_logo_blue.png";

function Completion(props) {
  const user = props.user;
  const tenant = props.tenant;
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (user && user?.tenant) {
      navigate(`/${user?.tenant}/home`, { replace: true });
    } else {
      navigate(`/${tenant}`, { replace: true });
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(135deg, #F3F4F6, #EDEDED)",
        padding: 2,
      }}
    >
      <Card
        variant="outlined"
        sx={{
          maxWidth: 500,
          width: "100%",
          textAlign: "center",
          boxShadow: "lg",
          borderRadius: "lg",
          padding: 4,
          background: "#ffffff",
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center" justifyContent={'space-between'}>
            <CelebrationIcon
            color="primary"
            sx={{
                fontSize: 100,
                marginBottom: 2,
                animation: "pop 1.5s ease-out infinite",
            }}
            />
            <img
                src={gyftbay_logo_blue}
                alt="Gyftbay Logo"
                style={{ width: 100, marginBottom: 2 }}
            />
        </Stack>
        <Typography
          level="h2"
          sx={{
            fontWeight: "bold",
            color: "#003A56",
            marginBottom: 2,
          }}
        >
          Thank You! 🎉
        </Typography>
        <Typography
          level="body1"
          sx={{
            color: "#666666",
            marginBottom: 3,
            lineHeight: 1.6,
          }}
        >
          Your gyft has been successfully processed! We will ensure it reaches
          the intended recipient. Click below to return to the homepage.
        </Typography>
        <Button
          variant="solid"
          color="primary"
          size="lg"
          startDecorator={<HomeIcon />}
          onClick={handleNavigation}
          sx={{
            fontWeight: "bold",
            paddingX: 4,
            ":hover": {
              transform: "translateY(-2px)",
              transition: "transform 0.2s",
            },
          }}
        >
          Back to Home
        </Button>
      </Card>
    </Box>
  );
}

export default Completion;
